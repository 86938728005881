import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { ProposalType } from 'types/EnterpriseProposal';
import { EnterpriseProposalPopup } from 'views/Enterprise/EnterpriseProposal/components';
import { usePermission } from 'hooks';
const EnterpriseProposalHistory = () => {
  const { id: _id } = useParams() as { id: string };

  const [dataSearch, onSearchChange] = useSearch({ _id });
  const [isOpen, setOpen] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<ProposalType>();
  const { requreUserPermission, ENTERPRISE_PROPOSAL } = usePermission();
  const { data } = useQuery(
    ['enterpriseService.fetchEnterpriseProposal', dataSearch],
    () => enterpriseService.fetchEnterpriseProposal(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Proposal History</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Created At</Trans>
              </TableCell>
              <TableCell>
                <Trans>Created By</Trans>
              </TableCell>
              <TableCell>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}</TableCell>
                <TableCell>{item.createdUser.fullname}</TableCell>
                <TableCell>{item.createdUser.position}</TableCell>
                <TableCell className='text-right'>
                  {requreUserPermission([ENTERPRISE_PROPOSAL]) && <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenUpdate(item);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={() => setOpen(false)}>
        <EnterpriseProposalPopup proposal={chosenUpdate!} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseProposalHistory;
