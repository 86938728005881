import {
  EnterpriseFinanceType,
  EnterpriseSettingType,
  EnterpriseSettingUpdateType,
  FinanceCriteriaType,
  FinanceIndexType,
  FinancePaginateType,
  FinanceParamsType,
  EmployeePaginateType,
  EmployeeParamsType,
  EnterpriseDivisionAssignType,
  CreditScoreCreateBody,
  CreditScoreCreateType,
  CreditScoreDiscountBody,
  CreditScoreLimitationBody,
  CreditScoreApproveBody,
  TrialConditionCreateType,
  SaveGuaranteeType,
  GuaranteeObjectType,
  RejectGuaranteeType,
  VerifyGuaranteeType,
  ConditionParamsType,
  ConditionPaginateType,
  ConditionSettingLastestType,
  ConditionUpdateBody,
  ConditionSettingType,
  EnterpriseGuaranteePaginateType,
  GuaranteeParamType,
  GuaranteeObjectParamType,
  EnterpriseServiceFeeParamsType,
  EnterpriseServiceFeePaginateType,
  EnterpriseServiceFeeInfoType,
  EnterpriseServiceFeeApplyInfoType,
  EnterpriseServiceFeeHistoryPaginateType,
  UpdateEnterpriseLatePaymentFeeType,
  UpdateEnterpriseServiceTaxFeeType,
  EnterpriseServiceFeeCategoryInfoType,
  EnterpriseServiceFeeCategoryInfoParamsType,
  EnterpriseServiceFeeSingleType,
  EnterpriseServiceFeeHistoryParamsType,
  AppendixPayloadType,
  AppendixDigitalRejectType,
  AppendixDigitalVerifyType,
  AppendixManualUploadType,
  CreditScoreAssessmentType,
  CreditScoreSetupLatestParamsType,
  EnterpriseWaitingPaymentParamsType,
  EnterpriseWaitingPaymentPaginateType,
  EnterpriseLatePaymentParamsType,
  EnterpriseLatePaymentPaginateType,
  EnterprisePaidPaymentParamsType,
  EnterprisePaidPaymentPaginateType,
  EnterpriseServiceFeePaymentCollectionSaveType,
  EnterpriseServiceFeePaymentMoveLateSaveType,
  EnterpriseServiceFeePaymentDocPaginateType,
  EnterpriseServiceFeePaymentDocParamsType,
  EnterpriseCollectLateFeePaymentType,
  AppendixDigitalActivateType,
  EnterpriseServiceFeeNoticeParamsType,
  EnterpriseServiceFeeNoticePaginateType,
  EnterpriseServiceFeeNoticeCreateOrGenerateParamsType,
  EnterpriseServiceFeeNoticeApproveType,
  EnterpriseServiceFeeNoticeDetailType,
  EnterpriseServiceFeeNoticeRejectType,
  EnterpriseServiceFeeNoticeSignType,
  EnterpriseServiceFeeNoticeGenerateType,
  EmployeeFeeSumPaginateType,
} from 'types/EnterpriseSetup';
import {
  AdvanceLaborCreateType,
  AdvanceLaborListType,
  AdvanceLevelCreateType,
  AdvanceLevelListType,
  AdvanceSeniorityCreateType,
  AdvanceSeniorityPaginateType,
  AdvanceSeniorityParamsType,
  WorkingBlockCreateType,
  WorkingBlockListType,
} from 'types/EnterpriseSetupAdvance';
import { client, clientUpload } from './axios';

const fetchEnterpriseFinances = ({ id, ...params }: FinanceParamsType): Promise<FinancePaginateType> =>
  client.get(`/api/v1/enterprise-setup/${id}/finances`, { params });
const createEnterpriseFinance = ({ id }: { id: string }): Promise<EnterpriseFinanceType> =>
  client.post(`/api/v1/enterprise-setup/${id}/finances`);
const importEnterpriseFinance = ({ id, fnId, formData }: { id: string; fnId: string; formData: FormData }) =>
  clientUpload.post(`/api/v1/enterprise-setup/${id}/finances/${fnId}/import`, formData);

const getEnterpriseFinance = ({ id, fnId }: { id: string; fnId: string }): Promise<EnterpriseFinanceType> =>
  clientUpload.get(`/api/v1/enterprise-setup/${id}/finances/${fnId}`);
const getEnterpriseCriteria = ({ id, fnId }: { id: string; fnId: string }): Promise<FinanceCriteriaType> =>
  clientUpload.get(`/api/v1/enterprise-setup/${id}/finances/${fnId}/criteria`);
const getEnterpriseIndexes = ({ id, fnId }: { id: string; fnId: string }): Promise<FinanceIndexType> =>
  clientUpload.get(`/api/v1/enterprise-setup/${id}/finances/${fnId}/indexes`);

const saveEnterpriseFinance = ({ id, fnId }: { id: string; fnId: string }) =>
  clientUpload.put(`/api/v1/enterprise-setup/${id}/finances/${fnId}/save`);
const completeEnterpriseFinance = ({ id, fnId }: { id: string; fnId: string }) =>
  clientUpload.put(`/api/v1/enterprise-setup/${id}/finances/${fnId}/complete`);

const fetchAdvanceLabors = ({ id }: { id: number }): Promise<AdvanceLaborListType> =>
  client.get(`/api/v1/enterprise-setup/${id}/advance-labors`);
const createAdvanceLabor = ({ id, ...body }: AdvanceLaborCreateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/advance-labors`, body);

const fetchAdvanceLevels = ({ id }: { id: number }): Promise<AdvanceLevelListType> =>
  client.get(`/api/v1/enterprise-setup/${id}/advance-levels`);
const createAdvanceLevel = ({ id, ...body }: AdvanceLevelCreateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/advance-levels`, body);

const fetchAdvanceSenioritys = ({ id, ...params }: AdvanceSeniorityParamsType): Promise<AdvanceSeniorityPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${id}/advance-seniority`, { params });
const createAdvanceSeniority = ({ id, ...body }: AdvanceSeniorityCreateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/advance-seniority`, body);
const updateAdvanceSeniority = ({ id, snId, ...body }: AdvanceSeniorityCreateType) =>
  client.put(`/api/v1/enterprise-setup/${id}/advance-seniority/${snId}`, body);
const removeAdvanceSeniority = ({ id, snId }: AdvanceSeniorityCreateType) =>
  client.delete(`/api/v1/enterprise-setup/${id}/advance-seniority/${snId}`);

const getGeneralSettings = ({ id }: { id: string }): Promise<EnterpriseSettingType> =>
  client.get(`/api/v1/enterprise-setup/${id}/general-settings`);
const fetchEmployees = ({ id, ...params }: EmployeeParamsType): Promise<EmployeePaginateType> =>
  client.get(`/api/v1/enterprise-setup/${id}/employees`, { params });
const updateEmployeeDivision = ({ id, ...body }: EnterpriseDivisionAssignType) =>
  client.put(`/api/v1/enterprise-setup/${id}/blocks`, body);

const fetchWorkingBlocks = ({ id }: { id: string }): Promise<WorkingBlockListType> =>
  client.get(`/api/v1/enterprise-setup/${id}/working-blocks`);
const createWorkingBlocks = ({ id, ...body }: WorkingBlockCreateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/working-blocks`, body);
const createOverdueDays = ({ id, ...body }: EnterpriseSettingUpdateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/overdue-days`, body);
const createPartTimeAdvance = ({ id, ...body }: EnterpriseSettingUpdateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/part-time-advance-rate`, body);
const createFullTimeLabors = ({ id, ...body }: EnterpriseSettingUpdateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/full-time-labors`, body);
const createFullTimeConditions = ({ id, ...body }: EnterpriseSettingUpdateType) =>
  client.post(`/api/v1/enterprise-setup/${id}/full-time-conditions`, body);

const getCreditScore = ({ enId, csId }: CreditScoreDiscountBody): Promise<CreditScoreCreateType> =>
  client.get(`/api/v1/enterprise-setup/${enId}/credit-scoring/${csId}`);
const createCreditScore = ({ id, ...body }: CreditScoreCreateBody): Promise<CreditScoreCreateType> =>
  client.post(`/api/v1/enterprise-setup/${id}/credit-scoring`, body);
const updateCreditScoreLimitation = ({ enId, csId, ...body }: CreditScoreLimitationBody): Promise<any> =>
  client.post(`/api/v1/enterprise-setup/${enId}/credit-scoring/${csId}/limitation`, body);
const updateCreditScoreDiscount = ({ enId, csId, ...body }: CreditScoreDiscountBody): Promise<any> =>
  client.post(`/api/v1/enterprise-setup/${enId}/credit-scoring/${csId}/discount-rate`, body);
const updateCreditScoreApprove = ({ enId, csId, ...body }: CreditScoreApproveBody): Promise<any> =>
  client.post(`/api/v1/enterprise-setup/${enId}/credit-scoring/${csId}/approval-decision`, body);

const createTrialCondition = ({ id, ...body }: TrialConditionCreateType): Promise<CreditScoreCreateType> =>
  client.post(`/api/v1/enterprise-setup/${id}/trail-condition`, body);
const fetchConditionSetting = ({ id, ...params }: ConditionParamsType): Promise<ConditionPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${id}/condition-setting`, { params });
const getConditionLastest = ({ id }: { id: number }): Promise<ConditionSettingLastestType> =>
  client.get(`/api/v1/enterprise-setup/${id}/condition-setting/latest`);
const getConditionById = ({ enId, id }: ConditionUpdateBody): Promise<ConditionSettingType> =>
  client.get(`/api/v1/enterprise-setup/${enId}/condition-setting/${id}`);

const updateApprovalDecision = ({ enId, csId, id, ...body }: ConditionUpdateBody): Promise<any> =>
  client.put(`/api/v1/enterprise-setup/${enId}/credit-scoring/${csId}/approval-decision/${id}`, body);
const updateTrialCondition = ({ enId, id, ...body }: ConditionUpdateBody): Promise<any> =>
  client.put(`/api/v1/enterprise-setup/${enId}/trail-condition/${id}`, body);

const fetchGuarantee = ({ enterpriseId, ...params }: GuaranteeObjectParamType): Promise<GuaranteeObjectType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/guarantee`, { params });
const saveGuarantee = ({ enterpriseId, ...body }: SaveGuaranteeType) =>
  client.post(`/api/v1/enterprise-setup/${enterpriseId}/guarantee`, body);
const approveGuaranteeNotice = ({ enterpriseId, noticeId }: { enterpriseId: number; noticeId: number }) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/guarantee/notice/${noticeId}/approve`);
const rejectGuaranteeNotice = ({ enterpriseId, noticeId, ...body }: RejectGuaranteeType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/guarantee/notice/${noticeId}/reject`, body);
const verifyGuaranteeNotice = ({ enterpriseId, noticeId, ...body }: VerifyGuaranteeType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/guarantee/notice/${noticeId}/verify`, body);

const getEnterpriseGuarantee = (params: GuaranteeParamType): Promise<EnterpriseGuaranteePaginateType> =>
  client.get(`/api/v1/enterprise-setup/enterprise/guarantee`, { params });

const fetchEnterpriseServiceFees = (
  params: EnterpriseServiceFeeParamsType,
): Promise<EnterpriseServiceFeePaginateType> => client.get(`/api/v1/enterprise-setup/service-fee`, { params });

const getEnterpriseServiceFeeInfo = ({ id }: { id: string }): Promise<EnterpriseServiceFeeInfoType> =>
  client.get(`/api/v1/enterprise-setup/${id}/service-fee/enterprise-info`);

const getEnterpriseServiceFeeApplyInfo = ({
  id,
}: {
  id: string | number;
}): Promise<EnterpriseServiceFeeApplyInfoType> => client.get(`/api/v1/enterprise-setup/${id}/service-fee`);

const fetchEnterpriseServiceFeeHistories = ({
  id,
  ...params
}: EnterpriseServiceFeeHistoryParamsType): Promise<EnterpriseServiceFeeHistoryPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${id}/service-fee/history`, { params });

const updateLatePaymentFee = ({
  id,
  ...body
}: UpdateEnterpriseLatePaymentFeeType): Promise<EnterpriseServiceFeeInfoType> =>
  client.put(`/api/v1/enterprise-setup/${id}/service-fee/late-payment-fee`, body);

const updateServiceTaxFee = ({
  id,
  ...body
}: UpdateEnterpriseServiceTaxFeeType): Promise<EnterpriseServiceFeeInfoType> =>
  client.put(`/api/v1/enterprise-setup/${id}/service-fee/service-tax`, body);

const getEnterpriseServiceFeeCategoryInfo = ({
  id,
  ...params
}: EnterpriseServiceFeeCategoryInfoParamsType): Promise<EnterpriseServiceFeeCategoryInfoType> =>
  client.get(`/api/v1/enterprise-setup/${id}/service-fee/category-info`, { params });

const saveSingleServiceFee = ({ id }: { id: string }): Promise<EnterpriseServiceFeeSingleType> =>
  client.post(`/api/v1/enterprise-setup/${id}/service-fee/single-service-fee`);

const generateFileAppendix = ({ id, ...body }: AppendixPayloadType): Promise<AppendixPayloadType> =>
  client.post(`/api/v1/enterprise-setup/${id}/service-fee/appendix/generate-file`, body);

const initAppendix = ({ id, ...body }: AppendixPayloadType): Promise<EnterpriseServiceFeeSingleType> =>
  client.post(`/api/v1/enterprise-setup/${id}/service-fee/appendix/init`, body);

const approveDigitalAppendix = ({
  enterpriseId,
  appendixId,
}: {
  enterpriseId: string;
  appendixId: number;
}): Promise<EnterpriseServiceFeeSingleType> =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/digital/approve `);

const rejectDigitalAppendix = ({ enterpriseId, appendixId, ...body }: AppendixDigitalRejectType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/reject`, body);

const verifyDigitalAppendix = ({
  enterpriseId,
  appendixId,
  ...body
}: AppendixDigitalVerifyType): Promise<EnterpriseServiceFeeSingleType> =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/digital/verify`, body);

const cancelDigitalAppendix = ({ enterpriseId, appendixId, ...body }: AppendixDigitalRejectType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/cancel`, body);

const activateAppendix = ({ enterpriseId, appendixId, ...body }: AppendixDigitalActivateType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/activate`, body);

const uploadManualAppendix = ({ enterpriseId, appendixId, ...body }: AppendixManualUploadType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/manual/upload`, body);

const completeManualAppendix = ({ enterpriseId, appendixId }: { enterpriseId: string; appendixId: number }) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/appendix/${appendixId}/manual/complete`);

const getCreditScoreAssessment = ({
  entId,
  csId,
}: {
  entId: string;
  csId: number;
}): Promise<CreditScoreAssessmentType> =>
  client.get(`/api/v1/enterprise-setup/${entId}/credit-scoring/${csId}/assessment`);

const getCreditScoreSetupLatest = ({ enterpriseId, ...params }: CreditScoreSetupLatestParamsType) =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/credit-scoring`, { params });

const getEnterpriseWaitingPaymentData = ({
  enterpriseId,
  ...params
}: EnterpriseWaitingPaymentParamsType): Promise<EnterpriseWaitingPaymentPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/waiting-for-payment`, { params });

const getEnterpriseLatePaymentData = ({
  enterpriseId,
  ...params
}: EnterpriseLatePaymentParamsType): Promise<EnterpriseLatePaymentPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/late-payment`, { params });

const getEnterprisePaidPaymentData = ({
  enterpriseId,
  ...params
}: EnterprisePaidPaymentParamsType): Promise<EnterprisePaidPaymentPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/paid-fee`, { params });

const confirmFeePayment = ({ enterpriseId, ...body }: EnterpriseServiceFeePaymentCollectionSaveType) =>
  client.post(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/collection`, body);

const moveToLateFeePayment = ({ enterpriseId, feePaymentId, ...body }: EnterpriseServiceFeePaymentMoveLateSaveType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/${feePaymentId}/late`, body);

const getFilePdfFeePayment = ({
  enterpriseId,
  feePaymentDocId
}: EnterpriseServiceFeePaymentDocParamsType): Promise<string> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/${feePaymentDocId}/fileupload`);
const fetchEnterpriseFeePaymentDocs = ({
  enterpriseId,
  feePaymentId,
  ...params
}: EnterpriseServiceFeePaymentDocParamsType): Promise<EnterpriseServiceFeePaymentDocPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/${feePaymentId}/docs`, { params });
const collectLateFeePayment = ({
  enterpriseId,
  feePaymentId,
  ...body
}: EnterpriseCollectLateFeePaymentType): Promise<EnterpriseServiceFeePaymentDocPaginateType> =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/${feePaymentId}/late/collect`, body);

const fetchEnterpriseFeeNotices = ({
  enterpriseId,
  ...params
}: EnterpriseServiceFeeNoticeParamsType): Promise<EnterpriseServiceFeeNoticePaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice`, { params });

const fetchEnterpriseFeeNoticeTrans = ({
  enterpriseId,
  ...params
}: EnterpriseServiceFeeNoticeParamsType): Promise<EmployeeFeeSumPaginateType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/transaction`, { params });

const createEnterpriseFeeNotice = ({ enterpriseId, ...body }: EnterpriseServiceFeeNoticeCreateOrGenerateParamsType) =>
  client.post(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice`, body);

const generateEnterpriseFeeNotice = ({
  enterpriseId,
  ...body
}: EnterpriseServiceFeeNoticeCreateOrGenerateParamsType): Promise<EnterpriseServiceFeeNoticeGenerateType> =>
  client.post(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/generate`, body);

const getEnterpriseFeeNoticeDetail = ({
  enterpriseId,
  noticeId,
}: EnterpriseServiceFeeNoticeApproveType): Promise<EnterpriseServiceFeeNoticeDetailType> =>
  client.get(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/${noticeId}`);

const approveEnterpriseFeeNotice = ({ enterpriseId, noticeId }: EnterpriseServiceFeeNoticeApproveType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/${noticeId}/approve`);
const rejectEnterpriseFeeNotice = ({ enterpriseId, noticeId, ...body }: EnterpriseServiceFeeNoticeRejectType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/${noticeId}/reject`, body);
const signEnterpriseFeeNotice = ({ enterpriseId, noticeId, ...body }: EnterpriseServiceFeeNoticeSignType) =>
  client.put(`/api/v1/enterprise-setup/${enterpriseId}/service-fee/notice/${noticeId}/sign`, body);

export default {
  getEnterpriseGuarantee,
  fetchEnterpriseFinances,
  createEnterpriseFinance,
  importEnterpriseFinance,

  getEnterpriseFinance,
  getEnterpriseCriteria,
  getEnterpriseIndexes,

  saveEnterpriseFinance,
  completeEnterpriseFinance,

  fetchAdvanceLabors,
  createAdvanceLabor,
  fetchAdvanceLevels,
  createAdvanceLevel,

  fetchAdvanceSenioritys,
  createAdvanceSeniority,
  updateAdvanceSeniority,
  removeAdvanceSeniority,

  getGeneralSettings,
  fetchEmployees,
  updateEmployeeDivision,

  fetchWorkingBlocks,
  createWorkingBlocks,
  createOverdueDays,
  createPartTimeAdvance,
  createFullTimeLabors,
  createFullTimeConditions,

  getCreditScore,
  createCreditScore,
  updateCreditScoreLimitation,
  updateCreditScoreDiscount,
  updateCreditScoreApprove,

  createTrialCondition,
  fetchConditionSetting,
  getConditionLastest,
  getConditionById,
  updateApprovalDecision,
  updateTrialCondition,

  fetchGuarantee,
  saveGuarantee,
  approveGuaranteeNotice,
  rejectGuaranteeNotice,
  verifyGuaranteeNotice,

  fetchEnterpriseServiceFees,
  getEnterpriseServiceFeeInfo,
  getEnterpriseServiceFeeApplyInfo,
  fetchEnterpriseServiceFeeHistories,
  updateLatePaymentFee,
  updateServiceTaxFee,
  getEnterpriseServiceFeeCategoryInfo,
  saveSingleServiceFee,

  generateFileAppendix,
  initAppendix,
  approveDigitalAppendix,
  rejectDigitalAppendix,
  verifyDigitalAppendix,
  cancelDigitalAppendix,
  activateAppendix,

  uploadManualAppendix,
  completeManualAppendix,

  getCreditScoreAssessment,
  getCreditScoreSetupLatest,

  getEnterpriseWaitingPaymentData,
  getEnterpriseLatePaymentData,
  getEnterprisePaidPaymentData,
  confirmFeePayment,
  moveToLateFeePayment,
  fetchEnterpriseFeePaymentDocs,
  getFilePdfFeePayment,
  collectLateFeePayment,

  fetchEnterpriseFeeNotices,
  fetchEnterpriseFeeNoticeTrans,
  createEnterpriseFeeNotice,
  generateEnterpriseFeeNotice,
  getEnterpriseFeeNoticeDetail,
  approveEnterpriseFeeNotice,
  rejectEnterpriseFeeNotice,
  signEnterpriseFeeNotice,
};
