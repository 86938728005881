import { Trans } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { employeeService } from 'services';
import { TableEmpty } from 'components';
import { getEmployeeType } from 'constants/employee';
import { formatNumber } from 'utils/common';
import { getEnterpriseStatus } from 'constants/enterprise';

const EmployeeWorking = () => {
  const { id: _id } = useParams() as { id: string };
  const userLocale = useActiveLocale();

  const [dataSearch, onSearchChange] = useSearch({ _id });

  const { data } = useQuery(
    ['employeeService.fetchEmployeeWorkings', dataSearch],
    () => employeeService.fetchEmployeeWorkings(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Working Information</Trans>
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Enterprise</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Level</Trans>
              </TableCell>
              <TableCell>
                <Trans>Division</Trans>
              </TableCell>
              <TableCell>
                <Trans>Labor Contract</Trans>
              </TableCell>
              <TableCell>
                <Trans>Seniority (Year)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Current Hourly Wage (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Average Salary Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.enterprise.name}</TableCell>
                <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.employeeLevel?.viContent : item.employeeLevel?.enContent}
                </TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.workingBlock?.viContent : item.workingBlock?.enContent}
                </TableCell>
                <TableCell>{userLocale === 'vi-VN' ? item.laborTerm?.viContent : item.laborTerm?.enContent}</TableCell>
                <TableCell>{formatNumber(item.workingYear)}</TableCell>
                <TableCell>{formatNumber(item.salaryHourly)}</TableCell>
                <TableCell>{formatNumber(item.salaryAverage)}</TableCell>
                <TableCell>{item.salaryPaymentDate}</TableCell>
                <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default EmployeeWorking;
