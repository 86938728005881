import { FileDataType } from 'types/Common';
import { UploadFileType, UploadObjectBas64Type, ObjectBas64Type } from 'types/Object';
import { client, clientUpload, clientDownload } from './axios';

const uploadFiles = (body: FormData): Promise<UploadFileType> => clientUpload.post(`/api/v1/objects/upload`, body);
const uploadBase64Files = (body: UploadObjectBas64Type): Promise<UploadFileType> => clientUpload.post(`/api/v1/objects/base64`, body);
const downloadFile = (key: string): Promise<BlobPart> =>
  clientDownload.get(`/api/v1/objects/${key}/download`,);
const downloadFileBytes = (key: string): Promise<BlobPart> =>
  clientDownload.get(`/api/v1/objects/${key}/downloadapp`,);
const mergeFile = (body: ObjectBas64Type[]): Promise<string> => clientUpload.post(`/api/v1/objects/merge`, body);
const downloadSignatureFile = (key: string): Promise<FileDataType> => client.get(`/api/v1/objects/${key}/download-signatures`);

export default {
  uploadFiles,
  uploadBase64Files,
  downloadFile,
  downloadFileBytes,
  downloadSignatureFile,
  mergeFile
};
