import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { PermissionName, RoleName } from 'types/Role';


const usePermission = () => {
  const { role, allowDs, enterprise, permission } = useSelector(profileSelector);

  const requirePermission = (permission: PermissionName) => {
    return role?.permissions && role?.permissions.includes(permission);
  };

  const requireRole = (roleName: RoleName, containMaster: boolean = true) => {
    return role?.name === roleName || (containMaster && (role?.name === 'MASTER' || role?.name === 'SUB_MASTER'));
  };

  const requireUserPC = () => {
    return ['MASTER', 'SUB_MASTER', 'SUPPORTER', 'CA_SENIOR', 'CREDIT_OFFICER']
      .concat(['CRM_SENIOR', 'CRM', 'ACCOUNTANT', 'ACC_CONTROLLER'])
      .includes(role?.name!);
  };
  const showAddReport = () => {
    return ['MASTER', 'SUB_MASTER', 'SUPPORTER', 'CRM_SENIOR'].includes(role?.name!);
  }
  const requreUserPermission = (per: string[]) => {
    if (permission != null) {
      for (var i = 0; i < per.length; i++) {
        if (permission.includes(per[i])) {
          return true;
        }
      }
    }
    return false;
  }

  const requireUserPCLeader = () => {
    return ['MASTER', 'SUB_MASTER', 'CA_SENIOR', 'CRM_SENIOR'].includes(role?.name!);
  };

  const requireUserPCManagerOrCRMLeader = () => {
    return ['MASTER', 'SUB_MASTER', 'CRM_SENIOR'].includes(role?.name!);
  };

  const requireUserPCManagerOrCALeader = () => {
    return ['MASTER', 'SUB_MASTER', 'CA_SENIOR'].includes(role?.name!);
  };

  const requireUserPCManager = () => {
    return ['MASTER', 'SUB_MASTER'].includes(role?.name!);
  };

  const isAccountantRoles = () => {
    return ['ACCOUNTANT', 'ACC_CONTROLLER'].includes(role?.name!);
  };

  const isCaRoles = () => {
    return ['MASTER', 'SUB_MASTER', 'CA_SENIOR', 'CREDIT_OFFICER'].includes(role?.name!);
  };

  const signable = () => {
    return allowDs || role?.name === 'MASTER';
  };

  const rolesignablenew = () => {
    return allowDs || role?.name === 'MASTER' || role?.name === 'SUB_MASTER';
  };

  const getEnterpriseId = () => {
    return enterprise?.id!;
  };

  const isRecordHistory = () => {
    return ['MASTER', 'SUB_MASTER', 'ACC_CONTROLLER'].includes(role?.name!);
  }
  const canActionAssignCheckList = () => {
    return ['MASTER', 'SUB_MASTER', 'CA_SENIOR'].includes(role?.name!);
  }
  const ENTERPRISE_VIEW = "1";
  const ENTERPRISE_CREATE = "2";
  const ENTERPRISE_DELETE = "3";
  const ENTERPRISE_UPDATE = "4";
  const ENTERPRISE_ASSIGN = "36";
  const ACCOUNT_ENTERPRISE_UPDATE = "24";
  const ACCOUNT_BANK_CREATEORUPDATE = "25";
  const ACCOUNT_BANK_VIEW = "26";
  const ACCOUNT_USERINFO_UPDATE = "27";
  const ACCOUNT_USERPASS_UPDATE = "28";
  const GENERALSETTING_COSTANDPRICE_UPDATE = "29";
  const GENERALSETTING_PROMOTION_LIST = "33";
  const GENERALSETTING_PROMOTION_DELETE = "34";
  const GENERALSETTING_PROMOTION_VIEW = "31";
  const GENERALSETTING_PROMOTION_CREATE = "30";
  const GENERALSETTING_PROMOTION_UPDATE = "35";
  const ENTERPRISE_REPORT = "44";
  const ENTERPRISE_APPRAISAL = "45";
  const ENTERPRISE_CONTRACT = "46";
  const ENTERPRISE_CONTRACT_ADD = "47";
  const ENTERPRISE_CONTRACT_APPROVE = "48";
  const ENTERPRISE_CONTRACT_DECLINE = "49";
  const ENTERPRISE_CONTRACT_SIGN = "50";
  const ENTERPRISE_CONTRACT_UPLOAD = "54";
  const ENTERPRISE_LEGAL = "55";
  const DIEMTINDUNG_THEMNGANH = "39";
  const DIEMTINDUNG_PHANQUYEN = "40";
  const DIEMTINDUNG_DANHSACH = "41";
  const DIEMTINDUNG_XEM = "43";
  const DIEMTINDUNG_CHITIET_LUU = "51";
  const DIEMTINDUNG_CHITIET_XOA = "52";
  const DIEMTINDUNG_CHITIET_THEMTIEUCHI = "53";
  const EMPLOYEE_VIEW = "59";
  const EMPLOYEE_CREATE = "60";
  const EMPLOYEE_DELETE = "61";
  const EMPLOYEE_UPDATE = "62";
  const EMPLOYEE_ASSIGN = "63";
  const PHANQUYEN_THEMNGUOIDUNG = "56";
  const PHANQUYEN_THEMNHOM = "57";
  const PHANQUYEN_XOA = "58";

  const EMPLOYEE_ACTIVE = "66";
  const EMPLOYEE_REPORT = "67";
  const EMPLOYEE_CONTRACT = "68";
  const EMPLOYEE_APPRAISAL = "69";
  const EMPLOYEE_CONTRACT_APPROVE = "88";
  const EMPLOYEE_CONTRACT_SIGN = "89";
  const EMPLOYEE_CONTRACT_ADD = "90";
  const KYDIENTU_THEM = "64";
  const KYDIENTU_SUA = "65";
  const ENTERPRISE_SETUP_VIEW = "94";
  const PHIDICHVUDN_DANHSACH = "70";
  const PHIDICHVUDN_XEM = "71";
  const PHIDICHVUDN_CHITIET_CAIDAT = "76";
  const PHIDICHVUDN_CHITIET_THUE_LUU = "77";
  const PHIDICHVUDN_CHITIET_LICHSU_XEM = "78";
  const PHIDICHVUDN_CHITIET_THONGBAO = "79";
  const PHIDICHVUDN_HOPDONG_TUCHOI = "86";
  const PHIDICHVUDN_HOPDONG_KY = "87";
  const TRANSACTION_HISTORY = "20";
  const TRANSACTION_SIGN_INCOME_NOTICE = "19";
  const TRANSACTION_XEMTAILIEU = "75";
  const TRANSACTION_VIEW = "21";
  const TRANSACTION_THONGBAOGIAODICH = "42";
  const TRANSACTION_GHINHAN = "73";
  const TRANSACTION_DUYETGIAODICH = "37";
  const TRANSACTION_DIEUCHINH = "72";
  const TRANSACTION_CHUYENNOQUAHAN = "74";
  const TRANSACTION_CHUNGTUTHANHTOAN = "38";
  const TRANSACTION_XEMTHONGBAODIEUCHINH = "80";
  const TRANSACTION_XEMLICHSUDIEUCHINH = "81";
  const TRANSACTION_KY_LICHSUDIEUCHINH = "82";
  const TRANSACTION_TUCHOI_LICHSUDIEUCHINH = "83";
  const TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN = "84";
  const TRANSACTION_SUA_LICHSUGHINHAN = "85";
  const ENTERPRISE_THONGTINTAICHINH = "91";
  const ENTERPRISE_DIEMTINDUNG = "92";
  const ENTERPRISE_DATDIEUKIENDUNGTHU = "93";
  const EMPLOYEE_SETUP_VIEW = "95";
  const EMPLOYEE_LEGAL = "96";
  const ENTERPRISE_PROPOSAL = "97";
  const EMPLOYEE_LIST_REPORT = "98";
  const EMPLOYEE_UPDATE_PAYMENT_RECEIVE = "99";
  const EMPLOYEE_UPDATE_BANK_ACCOUNT_INFO = "100";
  const ENTERPRISE_TANSUATCAPNHAT_THONGBAO = "101";
  const ENTERPRISE_DANHMUCTHAMDINH = "102";
  const ENTERPRISE_DEXUATTHAMDINH = "103";
  const ENTERPRISE_CAPNHAT_TANSUATCAPNHAT_THONGBAO = "104";
  const ENTERPRISE_CHUYENVAOLICHSU = "105";
  const ENTERPRISE_LUU_XACNHANBAOLANH = "106";
  const ENTERPRISE_DUYET_TUCHOI_XACNHANBAOLANH = "107";
  const ENTERPRISE_KY_TUCHOI_XACNHANBAOLANH = "108";
  const ENTERPRISE_XACNHAN_XACNHANBAOLANH = "109";
  const ENTERPRISE_HOANTHANH_CHISOTAICHINH = "110";
  const ENTERPRISE_KHOITAO_THONGTINTAICHINH = "111";
  const PHIDICHVUDN_CHITIET_GHINHAN = "112";
  const PHIDICHVUDN_CHITIET_QUAHAN = "113";
  const EMPLOYEE_DUYET_TUCHOI_DKUL = "114";
  const EMPLOYEE_DUYET_TUCHOI_CTHTNLD = "115";
  const NOTICE_CREATE_FEE_PAYMENT = "116";
  return {
    requirePermission,
    requireRole,
    requireUserPC,
    requireUserPCManager,
    requireUserPCManagerOrCRMLeader,
    requireUserPCManagerOrCALeader,
    requireUserPCLeader,
    isAccountantRoles,
    isCaRoles,
    signable,
    getEnterpriseId,
    rolesignablenew,
    isRecordHistory,
    requreUserPermission,
    showAddReport,
    canActionAssignCheckList,
    ENTERPRISE_VIEW,
    ENTERPRISE_SETUP_VIEW,
    ENTERPRISE_CREATE,
    ENTERPRISE_DELETE,
    ENTERPRISE_UPDATE,
    ENTERPRISE_ASSIGN,
    ACCOUNT_ENTERPRISE_UPDATE,
    ACCOUNT_BANK_CREATEORUPDATE,
    ACCOUNT_BANK_VIEW,
    ACCOUNT_USERINFO_UPDATE,
    ACCOUNT_USERPASS_UPDATE,
    GENERALSETTING_COSTANDPRICE_UPDATE,
    GENERALSETTING_PROMOTION_LIST,
    GENERALSETTING_PROMOTION_DELETE,
    GENERALSETTING_PROMOTION_VIEW,
    GENERALSETTING_PROMOTION_CREATE,
    GENERALSETTING_PROMOTION_UPDATE,
    ENTERPRISE_REPORT,
    ENTERPRISE_APPRAISAL,
    ENTERPRISE_CONTRACT,
    ENTERPRISE_CONTRACT_ADD,
    ENTERPRISE_CONTRACT_APPROVE,
    ENTERPRISE_CONTRACT_DECLINE,
    ENTERPRISE_CONTRACT_SIGN,
    ENTERPRISE_CONTRACT_UPLOAD,
    ENTERPRISE_LEGAL,
    DIEMTINDUNG_THEMNGANH,
    DIEMTINDUNG_PHANQUYEN,
    DIEMTINDUNG_DANHSACH,
    DIEMTINDUNG_XEM,
    DIEMTINDUNG_CHITIET_LUU,
    DIEMTINDUNG_CHITIET_XOA,
    DIEMTINDUNG_CHITIET_THEMTIEUCHI,
    EMPLOYEE_VIEW,
    EMPLOYEE_CREATE,
    EMPLOYEE_DELETE,
    EMPLOYEE_UPDATE,
    EMPLOYEE_ASSIGN,
    PHANQUYEN_THEMNGUOIDUNG,
    PHANQUYEN_THEMNHOM,
    PHANQUYEN_XOA,
    EMPLOYEE_CONTRACT_APPROVE,
    EMPLOYEE_ACTIVE,
    EMPLOYEE_REPORT,
    EMPLOYEE_LIST_REPORT,
    EMPLOYEE_CONTRACT,
    EMPLOYEE_APPRAISAL,
    EMPLOYEE_CONTRACT_SIGN,
    KYDIENTU_THEM,
    KYDIENTU_SUA,
    EMPLOYEE_CONTRACT_ADD,
    PHIDICHVUDN_DANHSACH,
    PHIDICHVUDN_XEM,
    PHIDICHVUDN_CHITIET_CAIDAT,
    PHIDICHVUDN_CHITIET_THUE_LUU,
    PHIDICHVUDN_CHITIET_LICHSU_XEM,
    PHIDICHVUDN_CHITIET_THONGBAO,
    PHIDICHVUDN_HOPDONG_TUCHOI,
    PHIDICHVUDN_HOPDONG_KY,
    TRANSACTION_HISTORY,
    TRANSACTION_SIGN_INCOME_NOTICE,
    TRANSACTION_XEMTAILIEU,
    TRANSACTION_VIEW,
    TRANSACTION_THONGBAOGIAODICH,
    TRANSACTION_GHINHAN,
    TRANSACTION_DUYETGIAODICH,
    TRANSACTION_DIEUCHINH,
    TRANSACTION_CHUYENNOQUAHAN,
    TRANSACTION_CHUNGTUTHANHTOAN,
    TRANSACTION_XEMTHONGBAODIEUCHINH,
    TRANSACTION_XEMLICHSUDIEUCHINH,
    TRANSACTION_KY_LICHSUDIEUCHINH,
    TRANSACTION_TUCHOI_LICHSUDIEUCHINH,
    TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN,
    TRANSACTION_SUA_LICHSUGHINHAN,
    ENTERPRISE_THONGTINTAICHINH,
    ENTERPRISE_DIEMTINDUNG,
    ENTERPRISE_DATDIEUKIENDUNGTHU,
    EMPLOYEE_SETUP_VIEW,
    EMPLOYEE_LEGAL,
    ENTERPRISE_PROPOSAL,
    EMPLOYEE_UPDATE_PAYMENT_RECEIVE,
    EMPLOYEE_UPDATE_BANK_ACCOUNT_INFO,
    ENTERPRISE_TANSUATCAPNHAT_THONGBAO,
    ENTERPRISE_DANHMUCTHAMDINH,
    ENTERPRISE_DEXUATTHAMDINH,
    ENTERPRISE_CAPNHAT_TANSUATCAPNHAT_THONGBAO,
    ENTERPRISE_CHUYENVAOLICHSU,
    ENTERPRISE_LUU_XACNHANBAOLANH,
    ENTERPRISE_DUYET_TUCHOI_XACNHANBAOLANH,
    ENTERPRISE_KY_TUCHOI_XACNHANBAOLANH,
    ENTERPRISE_XACNHAN_XACNHANBAOLANH,
    ENTERPRISE_HOANTHANH_CHISOTAICHINH,
    ENTERPRISE_KHOITAO_THONGTINTAICHINH,
    PHIDICHVUDN_CHITIET_GHINHAN,
    PHIDICHVUDN_CHITIET_QUAHAN,
    EMPLOYEE_DUYET_TUCHOI_DKUL,
    EMPLOYEE_DUYET_TUCHOI_CTHTNLD,
    NOTICE_CREATE_FEE_PAYMENT
  };
};

export default usePermission;

