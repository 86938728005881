import { Trans, t } from '@lingui/macro';
import { DesktopDatePicker } from '@mui/lab';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Checkbox,
  Dialog,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { DateTime } from 'luxon';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { formatNumber } from 'utils/common';
import { LatePaymentCollectFeePopup, PaymentSlipPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { useState } from 'react';
import { EnterpriseLatePaymentType } from 'types/EnterpriseSetup';

const EnterpriseLatePaymentList = ({ id }: { id: string }) => {
  const { control, watch, clearErrors } = useForm({ mode: 'onChange' });
  const [dataSearch, onSearchChange] = useSearch();
  const [openPaySlip, setOpenPaySlip] = useState(false);
  const [dataSelect, setDataSelect] = useState<EnterpriseLatePaymentType>();
  const [openCollectFeePopup, setOpenCollectFeePopup] = useState(false);

  const { data } = useQuery(
    ['enterpriseSetupService.getEnterpriseLatePaymentData', { enterpriseId: id, ...dataSearch }],
    () => enterpriseSetupService.getEnterpriseLatePaymentData({ enterpriseId: id, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  const { fromDate, toDate } = watch();

  return (
    <>
      <div className='flex items-center justify-between'>
        <Button
          variant='contained'
          onClick={() => {
            if (dataSelect) setOpenCollectFeePopup(true);
            return;
          }}
        >
          <Trans>Collect fee</Trans>
        </Button>
        <div className='space-x-4'>
          <Controller
            name='fromDate'
            defaultValue={DateTime.now()}
            control={control}
            rules={{
              validate: {
                isValid: (value: DateTime) => value && value.isValid,
                isAfter: (value) =>
                  DateTime.fromISO(value) < DateTime.fromISO(toDate) || 'From Date should be before the To Date',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (DateTime.fromISO(value) < DateTime.fromISO(toDate)) {
                    clearErrors(['fromDate', 'toDate']);
                    onSearchChange({ fromDate: value.toISO(), toDate: toDate.toISO() });
                  }
                }}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`From Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
          <Controller
            name='toDate'
            defaultValue={DateTime.now()}
            control={control}
            rules={{
              validate: {
                isValid: (value: DateTime) => value && value.isValid,
                isAfter: (value) =>
                  DateTime.fromISO(value) > DateTime.fromISO(fromDate) || 'To Date should be after the From Date',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (DateTime.fromISO(value) > DateTime.fromISO(fromDate)) {
                    clearErrors(['fromDate', 'toDate']);
                    onSearchChange({ fromDate: fromDate.toISO(), toDate: value.toISO() });
                  }
                }}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`To Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Trans>Late Payment Date </Trans>
              </TableCell>
              <TableCell>
                <Trans>Category</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>No. of days</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee before tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee after tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Penalty (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Outstanding Unpaid Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Slip</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelect(item);
                      else setDataSelect(undefined);
                    }}
                    checked={dataSelect?.id === item.id}
                  />
                </TableCell>
                <TableCell>
                  {item.latePaymentDate ? DateTime.fromISO(item.latePaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  <Trans>{getEnterpriseApplyFeeType(item.category)}</Trans>
                </TableCell>
                <TableCell>{item.transactionCode}</TableCell>
                <TableCell>{item.numbOfDays}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeBeforeTax)}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeAfterTax)}</TableCell>
                <TableCell>{item.penalty}</TableCell>
                <TableCell>{formatNumber(item.outstandingUnpaidAmount)}</TableCell>
                <TableCell>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setDataSelect(item);
                      setOpenPaySlip(true);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={openPaySlip} onClose={() => setOpenPaySlip(false)}>
        <PaymentSlipPopup enterpriseId={id} feePaymentId={dataSelect?.id!} onClose={() => setOpenPaySlip(false)} />
      </Dialog>

      <Dialog fullWidth maxWidth='sm' open={openCollectFeePopup} onClose={() => setOpenCollectFeePopup(false)}>
        <LatePaymentCollectFeePopup
          enterpriseId={id}
          dataSelect={dataSelect!}
          onClose={() => setOpenCollectFeePopup(false)}
        />
      </Dialog>
    </>
  );
};

export default EnterpriseLatePaymentList;
