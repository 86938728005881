import { t } from '@lingui/macro';
import { formatNumber } from 'utils/common';

export const PayingStatus = 'PAYING';
export const PaidStatus = 'PAID';
export const RejectedStatus = 'REJECTED';
export const WaitSettlementStatus = 'WAIT_SETTLEMENT';
export const RevisedStatus = 'REVISED';
export const WaitSettlementApprovalStatus = 'WAIT_SETTLEMENT_APPROVAL';
export const SettledStatus = 'SETTLED';
export const OverdueStatus = 'OVERDUE';
export const WaitCollectionApprovalStatus = 'WAIT_COLLECTION_APPROVAL';
export const OverdueCompletedStatus = 'OVERDUE_COMPLETED';

export const NoticeStatusInit = 'INIT';
export const NoticeStatusSigned = 'SIGNED';

export const SettlementRecordStatusRequested = 'REQUESTED';
export const SettlementRecordStatusApproved = 'APPROVED';

export const TransRevisedStatusInit = 'INIT';
export const TransRevisedStatusReject = 'REJECTED';
export const TransRevisedStatusSigned = 'SIGNED';

export const DocTypePaymentSlip = 'PAYMENT_SLIP';
export const SettlementRecordDocType = 'SETTLEMENT_RECORD';
export const OverdueRecordDocType = 'OVERDUE_RECORD';

export const getTransactionStatus = (status: string | undefined) => {
  if (status) {
    const MAP_TRANSACTION_STATUSES: { [char: string]: string } = {
      PAYING: t`Paying`,
      PAID: t`Paid`,
      REJECTED: t`Reject`,
      WAIT_SETTLEMENT: t`Waiting for settlement`,
      REVISED: t`Revised`,
      WAIT_SETTLEMENT_APPROVAL: t`Waiting for Approval`,
      SETTLED: t`Settled`,
      OVERDUE: t`Overdue`,
      WAIT_COLLECTION_APPROVAL: t`Waiting for Collection Approval`,
      OVERDUE_COMPLETED: t`Overdue Completed`,
      PARTNER_PAYING: t`Wait for partner payment`,
      PARTNER_PAID: t`Paid for Partner`,
    };
    return MAP_TRANSACTION_STATUSES[status];
  }
  return '';
};

export const getPaymentMethod = (method: string | undefined) => {
  if (method) {
    const MAP_PAYMENT_METHODS: { [char: string]: string } = {
      FULL: t`100% Payment to Employee's Designated Bank Account`,
      PART: t`Pay the advanced amount to Platform Company & Pay the rest to Employee`,
    };
    return MAP_PAYMENT_METHODS[method];
  }
  return '';
};

export const getPaymentRecipient = (method: string | undefined) => {
  if (method) {
    const MAP_PAYMENT_METHODS: { [char: string]: string } = {
      FULL: t`Employee`,
      PART: t`Payroll Company`,
    };
    return MAP_PAYMENT_METHODS[method];
  }
  return '';
};

export const getPromotionName = (minimumRequestAmount: number | undefined,
  promotionAmount: number | undefined, promotionDiscountRate: number | undefined): string => {
  if (promotionAmount && promotionDiscountRate) {
    return t`Discount ${formatNumber(promotionDiscountRate)}% and ${formatNumber(promotionAmount)}đ for transaction with minimum amount of ${formatNumber(minimumRequestAmount)}đ`;
  }
  if (promotionAmount) {
    return t`Discount ${formatNumber(promotionAmount)}đ for transaction with minimum amount of ${formatNumber(minimumRequestAmount)}đ`;
  }
  if (promotionDiscountRate) {
    return t`Discount ${formatNumber(promotionDiscountRate)}% for transaction with minimum amount of ${formatNumber(minimumRequestAmount)}đ`;
  }
  return '';
};
