import { useState } from 'react';
import { PopupController } from 'types/Common';
import {
  ConditionFullTimePopupStep1,
  ConditionFullTimePopupStep2,
  ConditionFullTimePopupStep3,
  ConditionFullTimePopupStep4,
} from 'views/ClientSetup/Employee/popups';

type PopupProps = PopupController & {
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
  stepCurrent?: number;
};

const FullTimePopup = ({ pasId, stepCurrent, enterpriseEmployeeId, employeeId, onClose }: PopupProps) => {
  const [step, setStep] = useState(stepCurrent || 1);

  return (
    <>
      {step === 1 && (
        <ConditionFullTimePopupStep1
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => setStep(2)}
        />
      )}
      {step === 2 && (
        <ConditionFullTimePopupStep2
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => onClose()}
        />
      )}
      {step === 3 && (
        <ConditionFullTimePopupStep3
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
          onNext={() => onClose()}
        />
      )}
      {step === 4 && (
        <ConditionFullTimePopupStep4
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          pasId={pasId}
          onBack={() => onClose()}
        />
      )}
    </>
  );
};

export default FullTimePopup;