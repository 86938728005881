import { client } from './axios';
import {
  TransOverduePaginateType,
  TransOverdueParamsType,
  TransOverdueRecordCalculationType,
  TransCalculatedOverdueType,
  TransOverdueGroupType,
  TransCreateOverdueRecordType,
  TransOverdueGuaranteeNoticePaginateType,
  GuaranteeTransactionRequestType,
  GuaranteeNoticeRequestType,
  GuaranteeNoticeSignType,
  GuaranteeNoticeRejectType,
  ListEnterpriseType,
  GuaranteeNoticeFileType,
  TransOverdueGuaranteeNoticeType,
  GuaranteeTransactionType,
  OverdueTransactionRequestType,
  OverdueNoticeRequestType,
  OverdueNoticeSignType,
  OverdueNoticeRejectType,
  OverdueNoticeFileType,
  OverdueTransactionType,
  TransOverdueNoticePaginateType,
  TransOverdueNoticeType,
  ListEmployeeType,
  OverdueEmployeeParamType,
} from 'types/TransactionOverdue';
import { PaginateParamsType } from 'types/Common';

const getOverdueTransaction = ({ id }: { id: string }): Promise<TransOverdueGroupType> =>
  client.get(`/api/v1/transactions/overdue/${id}/info`);
const fetchOverdueTransactions = (params: TransOverdueParamsType): Promise<TransOverduePaginateType> =>
  client.get(`/api/v1/transactions/overdue/overdue`, { params });
const fetchCompletedTransactions = (params: TransOverdueParamsType): Promise<TransOverduePaginateType> =>
  client.get(`/api/v1/transactions/overdue/completed`, { params });
const calculateOverdueTransaction = ({ transactionId, ...body }: TransOverdueRecordCalculationType): Promise<TransCalculatedOverdueType[]> =>
  client.post(`/api/v1/transactions/overdue/${transactionId}/calculated-overdue-transaction`, body);
const createOverdueRecordTransaction = ({ id, ...body }: TransCreateOverdueRecordType) =>
  client.post(`/api/v1/transactions/overdue/${id}`, body);
const updateOverdueRecordTransaction = ({ id, recordId, ...body }: { recordId: number } & TransCreateOverdueRecordType) =>
  client.put(`/api/v1/transactions/overdue/${id}/record/${recordId}`, body);
const approveOverdueRecordTransaction = ({ id, recordId }: { id: number, recordId: number }) =>
  client.put(`/api/v1/transactions/overdue/${id}/record/${recordId}/approval`);
// Guarantee
const getOverdueTransactionGuaranteeNotices = (params: PaginateParamsType): Promise<TransOverdueGuaranteeNoticePaginateType> =>
  client.get(`/api/v1/transactions/overdue/notice/guarantee`, { params });
const fetchOverdueGuaranteeEnterprises = (): Promise<ListEnterpriseType> =>
  client.get(`/api/v1/transactions/overdue/notice/guarantee/enterprises`);
const getGuaranteeTransactions = (body: GuaranteeTransactionRequestType): Promise<GuaranteeTransactionType[]> =>
  client.post(`/api/v1/transactions/overdue/notice/guarantee/transactions`, body);
const getGuaranteeNoticeFile = (body: GuaranteeNoticeRequestType): Promise<GuaranteeNoticeFileType> =>
  client.post(`/api/v1/transactions/overdue/notice/guarantee/generate-file`, body);
const confirmGuaranteeNotice = (body: GuaranteeNoticeRequestType): Promise<GuaranteeNoticeFileType> =>
  client.post(`/api/v1/transactions/overdue/notice/guarantee/confirm`, body);
const getGuaranteeNotice = ({ id }: { id: number }): Promise<TransOverdueGuaranteeNoticeType> =>
  client.get(`/api/v1/transactions/overdue/notice/guarantee/${id}`);
const aprroveGuaranteeNotice = ({ id }: { id: number }) =>
  client.put(`/api/v1/transactions/overdue/notice/guarantee/${id}/approval`);
const verifyGuaranteeNotice = ({ id, ...body }: GuaranteeNoticeSignType) =>
  client.put(`/api/v1/transactions/overdue/notice/guarantee/${id}/verify`, body);
const rejectGuaranteeNotice = ({ id, ...body }: GuaranteeNoticeRejectType) =>
  client.put(`/api/v1/transactions/overdue/notice/guarantee/${id}/reject`, body);
// Overdue
const getOverdueTransactionOverdueNotices = (params: PaginateParamsType): Promise<TransOverdueNoticePaginateType> =>
  client.get(`/api/v1/transactions/overdue/notice/overdue`, { params });
const fetchOverdueEmployees = (params: OverdueEmployeeParamType): Promise<ListEmployeeType> =>
  client.get(`/api/v1/transactions/overdue/notice/overdue/employees`, { params });
const getOverdueTransactions = (body: OverdueTransactionRequestType): Promise<OverdueTransactionType[]> =>
  client.post(`/api/v1/transactions/overdue/notice/overdue/transactions`, body);
const getOverdueNoticeFile = (body: OverdueNoticeRequestType): Promise<OverdueNoticeFileType> =>
  client.post(`/api/v1/transactions/overdue/notice/overdue/generate-file`, body);
const confirmOverdueNotice = (body: OverdueNoticeRequestType): Promise<OverdueNoticeFileType> =>
  client.post(`/api/v1/transactions/overdue/notice/overdue/confirm`, body);
const getOverdueNotice = ({ id }: { id: number }): Promise<TransOverdueNoticeType> =>
  client.get(`/api/v1/transactions/overdue/notice/overdue/${id}`);
const aprroveOverdueNotice = ({ id }: { id: number }) =>
  client.put(`/api/v1/transactions/overdue/notice/overdue/${id}/approval`);
const verifyOverdueNotice = ({ id, ...body }: OverdueNoticeSignType) =>
  client.put(`/api/v1/transactions/overdue/notice/overdue/${id}/verify`, body);
const rejectOverdueNotice = ({ id, ...body }: OverdueNoticeRejectType) =>
  client.put(`/api/v1/transactions/overdue/notice/overdue/${id}/reject`, body);

export default {
  getOverdueTransaction,
  fetchOverdueTransactions,
  fetchCompletedTransactions,
  calculateOverdueTransaction,
  createOverdueRecordTransaction,
  updateOverdueRecordTransaction,
  approveOverdueRecordTransaction,
  // Guarantee
  getOverdueTransactionGuaranteeNotices,
  fetchOverdueGuaranteeEnterprises,
  getGuaranteeTransactions,
  getGuaranteeNoticeFile,
  getGuaranteeNotice,
  confirmGuaranteeNotice,
  aprroveGuaranteeNotice,
  verifyGuaranteeNotice,
  rejectGuaranteeNotice,
  // Overdue
  getOverdueTransactionOverdueNotices,
  fetchOverdueEmployees,
  getOverdueTransactions,
  getOverdueNoticeFile,
  getOverdueNotice,
  confirmOverdueNotice,
  aprroveOverdueNotice,
  verifyOverdueNotice,
  rejectOverdueNotice,
};
