import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { categoryService, enterpriseSetupService, queryClient } from 'services';
import { EnterpriseType } from 'types/Enterprise';

const AttendanceWorkingBlocks = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm();

  const { data: workingTypes } = useQuery(['fetchWorkingTypes'], () => categoryService.fetchWorkingTypes());
  const { data: weekDays } = useQuery(['fetchWeekDays'], () => categoryService.fetchWeekDays());
  const secondWeekDays = weekDays ? [...weekDays] : [];
  secondWeekDays.splice(0, 0, { id: 0, isOffDefault: 0, enContent: 'Select', viContent: 'Chọn' });

  const id = enterprise?.id.toString()!;

  const { data } = useQuery(
    ['enterpriseSetupService.fetchWorkingBlocks', { id }],
    () => enterpriseSetupService.fetchWorkingBlocks({ id }),
    {
      onSuccess: ({ blocks }) => {
        blocks.forEach((block) => {
          setValue(block.workingBlockId + '-startDay', block.startDay ?? '');
          setValue(block.workingBlockId + '-endDay', block.endDay ?? '');
          setValue(block.workingBlockId + '-workingTypeId', block.workingTypeId);
          setValue(block.workingBlockId + '-firstDayOffId', block.firstDayOffId);
          setValue(block.workingBlockId + '-secondDayOffId', block.secondDayOffId);
          setValue(block.workingBlockId + '-note', block.note ?? '');
        });
      },
    },
  );
  const { blocks = [], editable = false } = data ?? {};

  const { mutate: createWorkingBlocks, isLoading } = useMutation(enterpriseSetupService.createWorkingBlocks, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchWorkingBlocks');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createWorkingBlocks({
        id,
        blocks: blocks
          .map((block) => ({
            startDay: values[block.workingBlockId + '-startDay'] as number,
            endDay: values[block.workingBlockId + '-endDay'] as number,
            firstDayOffId: values[block.workingBlockId + '-firstDayOffId'] as number,
            secondDayOffId: values[block.workingBlockId + '-secondDayOffId'] as number,
            workingBlockId: block.workingBlockId,
            workingTypeId: values[block.workingBlockId + '-workingTypeId'] as number,
            note: values[block.workingBlockId + '-note'] as string,
          }))
          .filter((item) => item.workingTypeId && item.firstDayOffId),
      });
    })();
  };

  return (
    <div>
      <Typography variant='h4' className='mb-4'>
        <Trans>Management of Employee's Attendance</Trans>
      </Typography>
      <Grid container columnSpacing={4}>
        {[0, 3]
          .map((start) => blocks.slice(start, start + 3))
          .map((array, slide) => (
            <Grid item md={6} key={slide}>
              <Grid container rowSpacing={2}>
                {array.map((block, index) => (
                  <Grid item md={12} key={index}>
                    <Typography variant='h5' className='mb-2'>
                      {userLocale === 'vi-VN' ? block.workingBlock.viContent : block.workingBlock.enContent}
                    </Typography>
                    <Paper variant='outlined' className='p-3'>
                      <Grid container columnSpacing={2} rowSpacing={3}>
                        <Grid item sm={6}>
                          <Controller
                            name={block.workingBlockId + '-startDay'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t`Attendance Starting Date`}
                                error={invalid}
                                InputProps={{ inputComponent: InputNumber as any }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Controller
                            name={block.workingBlockId + '-endDay'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label={t`Attendance Closing Date`}
                                error={invalid}
                                InputProps={{ inputComponent: InputNumber as any }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Controller
                            name={block.workingBlockId + '-workingTypeId'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`Attendance Time`} error={invalid}>
                                {workingTypes?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={6}></Grid>
                        <Grid item sm={6}>
                          <Controller
                            name={block.workingBlockId + '-firstDayOffId'}
                            defaultValue=''
                            control={control}
                            rules={{ required: slide === 0 }}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`(1) Full day off`} error={invalid}>
                                {weekDays?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Controller
                            name={block.workingBlockId + '-secondDayOffId'}
                            defaultValue=''
                            control={control}
                            render={({ field, fieldState: { invalid, error } }) => (
                              <TextField {...field} select fullWidth label={t`(2) Half/Full day off`} error={invalid}>
                                {secondWeekDays?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <Controller
                            name={block.workingBlockId + '-note'}
                            defaultValue=''
                            control={control}
                            render={({ field }) => <TextField {...field} fullWidth label={t`Note`} />}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
      </Grid>
      {editable && (
        <div className='flex justify-end mt-4'>
          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      )}
    </div>
  );
};

export default AttendanceWorkingBlocks;
