import { BankAccountCreateType, BankAccountPaginateType, BankAccountParamsType } from 'types/BankAccount';
import { FileDataType } from 'types/Common';
import {
  EnterpriseCreateType,
  EnterpriseMeetingType,
  EnterprisePaginateType,
  EnterpriseParamsType,
  EnterpriseType,
  EnterpriseUpdateType,
  EnterpriseUserAssign,
  EnterpriseParamsPaymentScheduleType,
  EnterpriseSummaryPaymentScheduleType,
  EnterpriseSummaryPaymentScheduleNoteType,
  CreateEnterprisePaymentScheduleNoteType,
  PaymentSalaryParamsType,
  PaymentSalaryScheduleType,
  PagePaymentSalaryAndFee,
  PaymentScheduleParamsType,
  EntPaymentScheduleResponse,
  ClientTransPage,
  ClientOnBoard,
} from 'types/Enterprise';
import {
  AppraisalCreateType,
  AppraisalDataType,
  AppraisalHistoryPaginateType,
  AppraisalParamsType,
} from 'types/EnterpriseAppraisal';
import {
  ChecklistDocCreateType,
  ChecklistDocPaginateType,
  ChecklistDocParamsType,
  ChecklistLegalPaginateType,
  ChecklistStatusCreateType,
  ChecklistStatusType,
} from 'types/EnterpriseChecklist';
import {
  ContractApproveType,
  ContractCreateType,
  ContractLastestType,
  ContractPaginateType,
  ContractParamsType,
  ContractRejectType,
  ContractType,
} from 'types/EnterpriseContract';
import {
  FrequencyPaymentType,
  FrequencyPaymentUpdateType,
  FrequencyType,
  FrequencyUpdateType,
} from 'types/EnterpriseFrequency';
import { ProposalCreateType, ProposalPaginateType, ProposalParamsType, ProposalType } from 'types/EnterpriseProposal';
import { ReportCreateType, ReportPaginateType, ReportParamsType } from 'types/EnterpriseReport';
import { UploadFileType } from 'types/Object';
import { client, clientUpload } from './axios';
import { WorkingPaginateType, AttendanceRetailByIdOutput } from 'types/EmployeeWorking';
const getEnterpriseById = ({ id }: { id: string }): Promise<EnterpriseType> => client.get(`/api/v1/enterprises/${id}`);
const fetchEnterprises = (params?: EnterpriseParamsType): Promise<EnterprisePaginateType> =>
  client.get(`/api/v1/enterprises`, { params });
const fetchStatuses = (): Promise<string[]> => client.get(`/api/v1/enterprises/statuses`);
const createEnterprise = (body: EnterpriseCreateType) => client.post(`/api/v1/enterprises`, body);
const updateEnterprise = ({ id, ...body }: EnterpriseUpdateType) => client.put(`/api/v1/enterprises/${id}`, body);
const importEnterprises = (body: FormData): Promise<UploadFileType> =>
  clientUpload.post(`/api/v1/enterprises/import`, body);

const fetchPaymentSchedule = (
  params: EnterpriseParamsPaymentScheduleType,
): Promise<EnterpriseSummaryPaymentScheduleType> => client.get(`/api/v1/enterprises/payment-schedule`, { params });

const fetchPaymentScheduleNote = ({ date }: { date: string }): Promise<EnterpriseSummaryPaymentScheduleNoteType> =>
  client.get(`/api/v1/enterprises/payment-schedule-note`, { params: { date } });

const savePaymentScheduleNote = (body: CreateEnterprisePaymentScheduleNoteType) =>
  client.post(`/api/v1/enterprises/payment-schedule`, body);

const getEnterpriseMeeting = (): Promise<EnterpriseMeetingType[]> => client.get(`/api/v1/enterprises/meeting`);
const assignUserCrm = (body: EnterpriseUserAssign) => client.post(`/api/v1/enterprises/assign-crm`, body);
const assignUserCa = (body: EnterpriseUserAssign) => client.post(`/api/v1/enterprises/assign-ca`, body);

const fetchEnterpriseReports = ({ _id, ...params }: ReportParamsType): Promise<ReportPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/report`, { params });
const createEnterpriseReport = ({ _id, ...body }: ReportCreateType) =>
  client.post(`/api/v1/enterprises/${_id}/report`, body);

const getEnterprisePaymentMethod = ({ _id }: { _id: string }): Promise<FrequencyPaymentType> =>
  client.get(`/api/v1/enterprises/${_id}/payment-method`);
const updateEnterprisePaymentMethod = ({ _id, ...body }: FrequencyPaymentUpdateType) =>
  client.put(`/api/v1/enterprises/${_id}/payment-method`, body);
const confirmEnterprisePaymentMethod = () => client.put(`/api/v1/enterprises/payment-method/confirm`);

const getEnterpriseFrequency = ({ _id }: { _id: string }): Promise<FrequencyType> =>
  client.get(`/api/v1/enterprises/${_id}/frequency`);
const updateEnterpriseFrequency = ({ _id, ...body }: FrequencyUpdateType) =>
  client.post(`/api/v1/enterprises/${_id}/frequency`, body);

const fetchEnterpriseBankAccounts = ({ _id, ...params }: BankAccountParamsType): Promise<BankAccountPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/bank`, { params });
const createEnterpriseBankAccount = ({ _id, ...body }: BankAccountCreateType) =>
  client.post(`/api/v1/enterprises/${_id}/bank`, body);

const getProposalById = ({ id }: { id: number }): Promise<ProposalType> =>
  client.get(`/api/v1/enterprises/${id}/proposal-detail`);
const getEnterpriseProposal = ({ _id }: { _id: string }): Promise<ProposalType> =>
  client.get(`/api/v1/enterprises/${_id}/proposal`);
const fetchEnterpriseProposal = ({ _id, ...params }: ProposalParamsType): Promise<ProposalPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/proposal-history`, { params });
const createEnterpriseProposal = ({ _id }: { _id: string }) => client.post(`/api/v1/enterprises/${_id}/proposal`);
const updateEnterpriseProposal = ({ _id, ...body }: ProposalCreateType) =>
  client.put(`/api/v1/enterprises/${_id}/proposal`, body);

const getEnterpriseChecklistStatus = ({ _id }: { _id: string }): Promise<ChecklistStatusType> =>
  client.get(`/api/v1/enterprises/${_id}/legal`);
const createEnterpriseChecklistStatus = ({ _id, ...body }: ChecklistStatusCreateType) =>
  client.post(`/api/v1/enterprises/${_id}/legal`, body);

const fetchEnterpriseChecklist = ({ _id }: { _id: string }): Promise<ChecklistLegalPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/legal-type`);
const fetchEnterpriseChecklistDoc = ({ _id, ...params }: ChecklistDocParamsType): Promise<ChecklistDocPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/legal-doc`, { params });
const createEnterpriseChecklistDoc = ({ _id, ...body }: ChecklistDocCreateType) =>
  client.post(`/api/v1/enterprises/${_id}/legal-doc`, body);

const getAppraisalById = ({ id }: { id: number }): Promise<AppraisalDataType> =>
  client.get(`/api/v1/enterprises/${id}/appraisal-detail`);
const getEnterpriseAppraisal = ({ _id }: { _id: string }): Promise<AppraisalDataType> =>
  client.get(`/api/v1/enterprises/${_id}/appraisal`);
const fetchEnterpriseAppraisal = ({ _id, ...params }: AppraisalParamsType): Promise<AppraisalHistoryPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/appraisal-history`, { params });
const createEnterpriseAppraisal = ({ _id }: { _id: string }) => client.post(`/api/v1/enterprises/${_id}/appraisal`);
const updateEnterpriseAppraisal = ({ _id, ...body }: { _id: string; values: AppraisalCreateType[] }) =>
  client.put(`/api/v1/enterprises/${_id}/appraisal`, body);

const generateEnterpriseContract = ({ id, type }: { id: string; type: string }): Promise<FileDataType> =>
  client.get(`/api/v1/enterprises/${id}/contracts/generate?type=${type}`);
const getEnterpriseContract = ({ _id, id }: { _id: string; id: number }): Promise<ContractType> =>
  client.get(`/api/v1/enterprises/${_id}/contracts/${id}`);
const getEnterpriseLatest = ({ _id }: { _id: string }): Promise<ContractLastestType> =>
  client.get(`/api/v1/enterprises/${_id}/contracts/latest`);
const fetchEnterpriseContract = ({ _id, ...params }: ContractParamsType): Promise<ContractPaginateType> =>
  client.get(`/api/v1/enterprises/${_id}/contracts`, { params });
const createEnterpriseContract = ({ _id, ...body }: ContractCreateType) =>
  client.post(`/api/v1/enterprises/${_id}/contracts`, body);
const approveEnterpriseContract = ({ _id, id, ...body }: ContractApproveType) =>
  client.post(`/api/v1/enterprises/${_id}/contracts/${id}/approve`, body);
const rejectEnterpriseContract = ({ _id, id, ...body }: ContractRejectType) =>
  client.post(`/api/v1/enterprises/${_id}/contracts/${id}/reject`, body);
const fetchEnterprisePaymentSalarySchedules = ({
  ...params
}: PaymentSalaryParamsType): Promise<PaymentSalaryScheduleType> =>
  client.get(`/api/v1/enterprises/me/payment-schedule`, { params });
const fetchEnterprisePaymentSalaryAndFeeDetails = ({
  ...params
}: PaymentSalaryParamsType): Promise<PagePaymentSalaryAndFee> =>
  client.get(`/api/v1/enterprises/me/payment-schedule-details`, { params });
const updateSeniority = ({ id, ...body }: { id: number; valueSeniority: string }): Promise<string> => client.post(`/api/v1/enterprises/${id}/updateSeniority`, body);
const getSeniority = ({ id }: { id: number }): Promise<string> => client.post(`/api/v1/enterprises/${id}/getSeniority`);
const getPaymentSchedule = ({
  ...params
}: PaymentScheduleParamsType): Promise<EntPaymentScheduleResponse> =>
  client.get(`/api/v1/homepage/ent/payment-schedule-of-salary-and-fee`, { params });

const getTopClientTrans = ({ ...params }: { type: string, size: number }): Promise<ClientTransPage> => client.get(`/api/v1/homepage/clients/top`, { params });
const getClientOnboard = ({ ...params }: { type: string }): Promise<ClientOnBoard> => client.get(`/api/v1/homepage/clients/onboard`, { params });
const situationOfUsingLimit = ({ ...params }: { page: number, size: number }): Promise<WorkingPaginateType> => client.get(`/api/v1/homepage/clients/situationofusinglimit`, { params });
const getAllSituationOfUsingLimit = (): Promise<WorkingPaginateType> => client.get(`/api/v1/homepage/clients/getallsituationofusinglimit`);
const attendanceretail = ({ id, month, year }: { id: number, month: number, year: number } ): Promise<AttendanceRetailByIdOutput> => client.get(`/api/v1/homepage/clients/attendanceretail/${id}?month=${month}&year=${year}`);
export default {
  getEnterpriseById,
  fetchStatuses,
  fetchEnterprises,
  createEnterprise,
  updateEnterprise,
  importEnterprises,
  fetchPaymentSchedule,
  fetchPaymentScheduleNote,
  savePaymentScheduleNote,

  getEnterpriseMeeting,
  assignUserCrm,
  assignUserCa,

  fetchEnterpriseReports,
  createEnterpriseReport,

  getEnterprisePaymentMethod,
  updateEnterprisePaymentMethod,
  confirmEnterprisePaymentMethod,

  getEnterpriseFrequency,
  updateEnterpriseFrequency,

  fetchEnterpriseBankAccounts,
  createEnterpriseBankAccount,

  getProposalById,
  getEnterpriseProposal,
  fetchEnterpriseProposal,
  createEnterpriseProposal,
  updateEnterpriseProposal,

  getEnterpriseChecklistStatus,
  createEnterpriseChecklistStatus,
  fetchEnterpriseChecklist,
  fetchEnterpriseChecklistDoc,
  createEnterpriseChecklistDoc,

  getAppraisalById,
  getEnterpriseAppraisal,
  fetchEnterpriseAppraisal,
  createEnterpriseAppraisal,
  updateEnterpriseAppraisal,

  generateEnterpriseContract,
  getEnterpriseContract,
  getEnterpriseLatest,
  fetchEnterpriseContract,
  createEnterpriseContract,
  approveEnterpriseContract,
  rejectEnterpriseContract,

  fetchEnterprisePaymentSalarySchedules,
  fetchEnterprisePaymentSalaryAndFeeDetails,
  getPaymentSchedule,
  updateSeniority,
  getSeniority,

  getTopClientTrans,
  getClientOnboard,
  situationOfUsingLimit,
  getAllSituationOfUsingLimit,
  attendanceretail
};
