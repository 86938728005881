import React from 'react';
import { Trans, t } from '@lingui/macro';

import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Paper,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
} from '@mui/material';
import {
  OverdueCompletedStatus,
  OverdueStatus,
  RevisedStatus,
  SettledStatus,
  WaitSettlementApprovalStatus,
  WaitSettlementStatus,
} from 'constants/transaction';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { TransParamsType, TransType } from 'types/Transaction';
import { DateTime } from 'luxon';
import { transactionService } from 'services';
import { TableEmpty } from 'components';
import { formatNumber } from 'utils/common';
import TransSummary from './TransSummary';
import { getPaymentRecipient, getTransactionStatus } from 'constants/transaction';
import { downloadBase64 } from 'utils/common';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { TransIncomeNoticesPopup } from 'views/Transaction/TransView/popups';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '20%',
  },
  cellTitleBold: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '20%',
    fontWeight: 600,
  },
  cellTitleOne: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '40%',
  },
  cellValue: {
    width: '13%',
  },
  cellTitle4: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
  },
  cellValue4: {
    width: '20%',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const TransHisList = () => {
  const [transaction, setTransaction] = useState<TransType>();
  const classes = useStyles();
  const STATUSES = [
    { value: ' ', label: t`All` },
    {
      value: `${WaitSettlementStatus},${WaitSettlementApprovalStatus},${RevisedStatus}`,
      label: t`Waiting for settlement`,
    },
    { value: `${SettledStatus}`, label: t`Settled` },
    { value: `${OverdueStatus},`, label: t`Overdue` },
    { value: `${OverdueCompletedStatus}`, label: t`Overdue Completed` },
  ];
  const FEE_TYPES = [
    { value: 'all', label: t`All` },
    {
      value: 'FULL',
      label: t`Employee`,
    },
    { value: 'PART', label: t`Payroll Company` },
  ];
  const [dataSearch, onSearchChange] = useSearch({ sum: 1, transHisList: "true", viContent: useActiveLocale() === 'vi-VN' ? "true" : "false" });
  const [searchParams, setSearchParams] = useState({});
  const [isLoadingExport, setLoadingExport] = useState(false);
  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  const { control, handleSubmit } = useForm();
  const handleClickSearch = () => {
    handleSubmit((values: TransParamsType) => {
      values.enterpriseFeeType = values.enterpriseFeeType == "all" ? "" : values.enterpriseFeeType;
      type ValuesType = TransParamsType & {
        transactionDateFrom: DateTime;
        transactionDateTo: DateTime;
        salaryDateFrom: DateTime;
        salaryDateTo: DateTime;
      };
      const { transactionDateFrom, transactionDateTo, salaryDateFrom, salaryDateTo, ...others } = values as ValuesType;
      setSearchParams({
        ...others,
        transDateFrom: transactionDateFrom ? transactionDateFrom.toISO() : '',
        transDateTo: transactionDateTo ? transactionDateTo.toISO() : '',
        salaryDateFrom: salaryDateFrom ? salaryDateFrom.toISO() : '',
        salaryDateTo: salaryDateTo ? salaryDateTo.toISO() : '',
      });
    })();
  };
  const exportReport = () => {
    setLoadingExport(true);
    transactionService
      .exportTransHisList(dataSearch)
      .then(downloadBase64)
      .finally(() => {
        setLoadingExport(false);
      });
  };
  return (
    <div>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid item sm={3}>
          <Controller
            name='statuses'
            defaultValue=' '
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth select label={t`Status`} error={invalid} helperText={error?.message}>
                {STATUSES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='enterpriseFeeType'
            defaultValue='all'
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                select
                label={t`Recipient of Due Payment`}
                error={invalid}
                helperText={error?.message}
              >
                {FEE_TYPES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='employeeCode'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label={t`Staff ID`} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='employeeName'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label={t`Employee Name`} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='transactionDateFrom'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => !value || value.isValid },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                label={t`Transaction Date From`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='transactionDateTo'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => !value || value.isValid },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                label={t`Transaction Date To`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='salaryDateFrom'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => !value || value.isValid },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                label={t`Salary Date From`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='salaryDateTo'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => !value || value.isValid },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                label={t`Salary Date To`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='transactionCode'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField {...field} fullWidth label={t`Transaction ID`} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={9} className='flex items-center space-x-3'>
          <LoadingButton variant='contained' onClick={handleClickSearch}>
            <Trans>Search</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoadingExport} onClick={exportReport}>
            <Trans>Export Data</Trans>
          </LoadingButton>
        </Grid>
        <Grid item sm={12}>
          <TransSummary summary={data?.summary} />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Trans>Status</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Payment Date</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Recipient of Due Payment</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Transaction ID</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Transaction Date</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Staff ID</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Employee Name</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Request Amount (VND)</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Advanced Labors</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Unpaid Amount (VND)</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Transaction Notice</Trans>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{getTransactionStatus(item.status)}</TableCell>
                    <TableCell align='center'>
                      {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                    </TableCell>
                    <TableCell>{getPaymentRecipient(item.enterpriseFeeType)}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell align='center'>
                      {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                    </TableCell>
                    <TableCell>{item.employeeCode}</TableCell>
                    <TableCell>{item.employee?.name}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.correspondingLabor)}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.collectionAmount)}</TableCell>
                    <TableCell align='center'>
                      <Button size='small' color='info' onClick={async () => {
                        item.checkHisList = true;
                        setTransaction(item);
                        setOpen(true);
                      }}>
                        <Trans>View</Trans>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableEmpty data={items} />
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            fullWidth
            maxWidth='md'
            open={isOpen}
            onClose={() => setOpen(false)}
            classes={{ paper: classes.dialogPaper }}
          >
            <TransIncomeNoticesPopup transaction={transaction!} view={"ENTERPRISE"} onClose={() => setOpen(false)} />
          </Dialog>
          <div className='flex justify-center'>
            <Pagination
              page={paginate?.page ?? 1}
              count={paginate?.totalPage}
              onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default TransHisList;
