import { client } from './axios';
import {
  TransPaginateType,
  TransParamsType,
  TransType,
  TransUpdateStatusType,
  TransUpdateStatusesType,
  TransDocUpdateType,
  TransDocParamsType,
  TransDocPaginateType,
  TransSignIncomeNoticesType,
  TransSettlementRecordPaginateType,
  TransSettlementRecordParamsType,
  TransUpsertSettlementRecordType,
  TransAddOverdueType,
  TransReviseParamsType,
  TransRevisePaginateType,
  TransReviseConfirmType,
  TransSignReviseNoticesType,
  TransRejectReviseNoticesType,
  TransReviseType,
  TransPromotionParamType,
  TransPromotionListType,
  TransBankReportListType,
  TransStatistic,
  TransStatisticYear,

} from 'types/Transaction';
import { EnterpriseGuaranteesPaging, EnterpriseFeeNoticesPaging, NoticesDtoPaging } from 'types/EnterpriseSetup';
import { FileDataType } from 'types/Common';

const fetchTransactions = (params: TransParamsType): Promise<TransPaginateType> =>
  client.get(`/api/v1/transactions`, { params });
const getTransactionById = ({ id }: { id: string }): Promise<TransType> => client.get(`/api/v1/transactions/${id}`);

const moveStatus = ({ id, ...body }: TransUpdateStatusType) => client.post(`/api/v1/transactions/${id}/status`, body);
const moveStatuses = (body: TransUpdateStatusesType) => client.post(`/api/v1/transactions/status`, body);

const fetchTransactionDocs = ({ transId, ...params }: TransDocParamsType): Promise<TransDocPaginateType> =>
  client.get(`/api/v1/transactions/${transId}/doc`, { params });
const addTransactionDocs = ({ id, ...body }: TransDocUpdateType) => client.post(`/api/v1/transactions/${id}/doc`, body);
const deleteTransactionDocs = ({ id, transDocId }: { id: number; transDocId: number }) =>
  client.delete(`/api/v1/transactions/${id}/doc/${transDocId}`);

const getEnterpriseNotice = ({ id }: { id: number }): Promise<FileDataType> =>
  client.get(`/api/v1/transactions/${id}/enterprise-income-notice`);
const getEmployeeNotice = ({ id }: { id: number }): Promise<FileDataType> =>
  client.get(`/api/v1/transactions/${id}/employee-income-notice`);
const signIncomeNotices = ({ id, ...body }: TransSignIncomeNoticesType) =>
  client.post(`/api/v1/transactions/${id}/sign-income-notices`, body);

const fetchTransactionSettlementRecords = ({ transId, ...params }: TransSettlementRecordParamsType): Promise<TransSettlementRecordPaginateType> =>
  client.get(`/api/v1/transactions/${transId}/settlement`, { params });
const addTransactionSettlementRecord = ({ transId, ...body }: TransUpsertSettlementRecordType) =>
  client.post(`/api/v1/transactions/${transId}/settlement`, body);
const updateTransactionSettlementRecord = ({ id, transId, ...body }: TransUpsertSettlementRecordType) =>
  client.put(`/api/v1/transactions/${transId}/settlement/${id}`, body);
const approveTransactionSettlementRecord = ({ id, transId }: { id: number; transId: number }) =>
  client.put(`/api/v1/transactions/${transId}/settlement/${id}/approve`);
const addTransactionOverdue = ({ transId, ...body }: TransAddOverdueType) =>
  client.post(`/api/v1/transactions/${transId}/overdue`, body);

const fetchTransactionRevises = ({ transId, ...params }: TransReviseParamsType): Promise<TransRevisePaginateType> =>
  client.get(`/api/v1/transactions/${transId}/changes`, { params });
const getTransactionRevise = ({ id, transId }: { id: number; transId: number }): Promise<TransType> =>
  client.get(`/api/v1/transactions/${transId}/changes/${id}`);
const calculateTransactionRevise = ({ transId, ...params }: TransReviseConfirmType): Promise<TransType> =>
  client.get(`/api/v1/transactions/${transId}/changes/calculation`, { params });
const getTransactionReviseNotice = ({ id, transId }: { id: number; transId: number }): Promise<FileDataType> =>
  client.get(`/api/v1/transactions/${transId}/changes/${id}/notice`);
const addTransactionRevise = ({ transId, ...body }: TransReviseConfirmType): Promise<TransReviseType> =>
  client.post(`/api/v1/transactions/${transId}/changes`, body);
const signTransactionReviseNotice = ({ id, transId, ...body }: TransSignReviseNoticesType) =>
  client.put(`/api/v1/transactions/${transId}/changes/${id}/sign`, body);
const rejectTransactionRevise = ({ id, transId, ...body }: TransRejectReviseNoticesType) =>
  client.put(`/api/v1/transactions/${transId}/changes/${id}/reject`, body);

const getTransactionLimit = (): Promise<{
  availableAdvanceAmount: number;
  maxAdvanceAmount: number;
  maxAdvanceLabor: number;
}> => client.get(`/api/v1/transactions/limit`);

const getPromotions = (params: TransPromotionParamType): Promise<TransPromotionListType> => client.get(`/api/v1/transactions/promotions`, { params });

const calculateTransaction = (body: any) => client.post('/api/v1/transactions/calculate', body);
const postTransaction = (body: any) => client.post('/api/v1/transactions', body);
const getTransactionEmployeeIncomeNotice = (txId: number) =>
  client.get(`/api/v1/transactions/${txId}/employee-income-notice`);
const confirmTransaction = (txId: number) => client.post(`/api/v1/transactions/${txId}/confirm`);
const rejectTransaction = (txId: number) => client.post(`/api/v1/transactions/${txId}/reject`);
const sentOtp = (txId: number) => client.get(`/api/v1/transactions/${txId}/otp`);
const confirmOtp = (txId: number, otp: string) => client.post(`/api/v1/transactions/${txId}/otp`, { otp });

const listTransBanks = (): Promise<{ list: string[] }> => client.get(`/api/v1/transactions/bank-report/banks`);
const listTransBankReport = ({ ...params }: { bankCode: string }): Promise<TransBankReportListType> => client.get(`/api/v1/transactions/bank-report`, { params });
const exportTransBankReport = ({ ...params }: { bankCode: string }): Promise<FileDataType> => client.get(`/api/v1/transactions/bank-report/export`, { params });
const updateTransBankReport = ({ txId, ...body }: { txId: number, amount: number, content: string }) => client.post(`/api/v1/transactions/${txId}/bank-report`, body);

const statisticTrans = ({ ...params }: { dateFrom: string, dateTo: string }): Promise<TransStatistic> => client.get(`/api/v1/homepage/transactions`, { params });
const statisticTransYear = ({ ...params }: { year: number }): Promise<TransStatisticYear> => client.get(`/api/v1/homepage/transactions/year`, { params });

const exportTransDetailReport = ({ ...params }: { enterpriseTeeType: string, statuses: string }): Promise<FileDataType> => client.get(`/api/v1/transactions/detail-report/export`, { params });
const exportTransHisList = ({ ...params }: TransParamsType): Promise<FileDataType> => client.get(`/api/v1/transactions/trans-his-list/export`, { params });
const exportTransPcHisList = ({ ...params }: TransParamsType): Promise<FileDataType> => client.get(`/api/v1/transactions/history/export`, { params });

const listTransactionNotice = ({ ...params }:{ size: number, page: number , type: string, search: string}) : Promise<NoticesDtoPaging>=> client.get(`/api/v1/transactions/notify`, { params });
const listGuaranteeNotice = ({size, page }:{ size: number, page: number}) : Promise<EnterpriseGuaranteesPaging>=> client.get(`/api/v1/transactions/notify/guarantee?size=${size}&page=${page}`);
const listFeePaymentNotice = ({size, page }:{ size: number, page: number}): Promise<EnterpriseFeeNoticesPaging> => client.get(`/api/v1/transactions/notify/feepayment?size=${size}&page=${page}`);
const viewFileNotice = ({ id }:{ id: number }): Promise<string> => client.get(`/api/v1/transactions/notify/viewfile?id=${id}`);
const exportExcelData = ({ ...params }: { type: string, search: string }): Promise<FileDataType> => client.get(`/api/v1/transactions/notify/exportdata`, { params });
export default {
  fetchTransactions,
  getTransactionById,

  moveStatus,
  moveStatuses,

  fetchTransactionDocs,
  addTransactionDocs,
  deleteTransactionDocs,

  getEnterpriseNotice,
  getEmployeeNotice,
  signIncomeNotices,

  fetchTransactionSettlementRecords,
  addTransactionSettlementRecord,
  updateTransactionSettlementRecord,
  approveTransactionSettlementRecord,
  addTransactionOverdue,

  fetchTransactionRevises,
  getTransactionRevise,
  calculateTransactionRevise,
  getTransactionReviseNotice,
  addTransactionRevise,
  signTransactionReviseNotice,
  rejectTransactionRevise,
  getTransactionLimit,
  getPromotions,
  calculateTransaction,
  postTransaction,
  getTransactionEmployeeIncomeNotice,
  confirmTransaction,
  rejectTransaction,
  sentOtp,
  confirmOtp,

  listTransBanks,
  listTransBankReport,
  exportTransBankReport,
  updateTransBankReport,

  statisticTrans,
  statisticTransYear,

  exportTransDetailReport,
  exportTransHisList,
  exportTransPcHisList,

  listTransactionNotice,
  listGuaranteeNotice,
  listFeePaymentNotice,
  viewFileNotice,
  exportExcelData
};
