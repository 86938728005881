import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  debounce,
  Dialog,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { downloadBase64, formatNumber } from 'utils/common';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import { queryClient, transactionService } from 'services';
import { TransType } from 'types/Transaction';
import { IS_EPAYZ } from 'env';
import {
  RevisedStatus,
  OverdueCompletedStatus,
  OverdueStatus,
  SettledStatus,
  WaitCollectionApprovalStatus,
  WaitSettlementApprovalStatus,
  WaitSettlementStatus,
} from 'constants/transaction';
import { TableEmpty } from 'components';
import { TransIncomeNoticesPopup } from 'views/Transaction/TransView/popups';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const TransPaying = () => {
  const [dataSearch, onSearchChange] = useSearch({
    statuses: `${WaitSettlementStatus},${RevisedStatus},${WaitSettlementApprovalStatus},${SettledStatus},${OverdueStatus},${WaitCollectionApprovalStatus},${OverdueCompletedStatus}`,
  });
  const classes = useStyles();
  const [searchType, setSearchType] = useState('employeeName');
  const [isOpen, setOpen] = useState(false);
  const [isLoadingExport, setLoadingExport] = useState(false);
  const [transaction, setTransaction] = useState<TransType>();
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { data: sum } = useQuery(
    [
      'transactionService.fetchTransactions',
      { statues: `${WaitSettlementStatus},${RevisedStatus},${WaitSettlementApprovalStatus}`, sum: 1, size: 1 },
    ],
    () =>
      transactionService.fetchTransactions({
        statuses: `${WaitSettlementStatus},${RevisedStatus},${WaitSettlementApprovalStatus}`,
        sum: 1,
        size: 1,
      }),
  );

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const exportReport = () => {
    setLoadingExport(true);
    transactionService
      .exportTransDetailReport({
        enterpriseTeeType: 'PART',
        statuses: `${WaitSettlementStatus},${RevisedStatus},${WaitSettlementApprovalStatus}`,
      })
      .then(downloadBase64)
      .finally(() => {
        setLoadingExport(false);
      });
  };

  return (
    <div>
      <div className='border flex items-center space-x-3 mt-2 mb-2 p-2'>
        <Typography variant='h4'>
          <Trans>Payment Amount to</Trans> {IS_EPAYZ ? 'IFZ' : 'SCP'}
        </Typography>
        <Typography variant='h4'>{formatNumber(sum?.summary?.totalCollectionAmount)} (VND)</Typography>
        <LoadingButton variant='contained' loading={isLoadingExport} onClick={exportReport}>
          <Trans>Export Data</Trans>
        </LoadingButton>
      </div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction List</Trans>
          </Typography>
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='employeeErcNumber'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='employeeCode'>
              <Trans>Staff ID</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
          </TextField>
          <TextField
            placeholder={t`Search...`}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID Card No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Staff ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transferred Income (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.employee?.ercNumber}</TableCell>
                <TableCell>{item.employeeCode}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.enterpriseIncomeNotice?.code}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                <TableCell align='center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setTransaction(item);
                      setOpen(true);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={isOpen}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <TransIncomeNoticesPopup
          transaction={transaction!}
          view='ENTERPRISE'
          onClose={() => {
            setOpen(false);
            queryClient.invalidateQueries('transactionService.fetchTransactions');
          }}
        />
      </Dialog>
    </div>
  );
};

export default TransPaying;
