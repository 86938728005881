import { Trans, t } from '@lingui/macro';

import { useActiveLocale } from 'hooks/useActiveLocale';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Typography,
  debounce,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { employeeLaborService, categoryService } from 'services';
import { useState, useCallback, useEffect } from 'react';
import { TableEmpty } from 'components';
import { EnterpriseType } from 'types/Enterprise';
import { getEmployeeType } from 'constants/employee';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';

const EmployeeList = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const [dataSearch, onSearchChange] = useSearch();
  const userLocale = useActiveLocale();
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const { data } = useQuery(
    ['employeeLaborService.fetchEmployeeLabors', { id: enterprise?.id, ...dataSearch }],
    () => employeeLaborService.fetchEmployeeLabors({ id: enterprise?.id, ...dataSearch }),
    { keepPreviousData: true },
  );
  useEffect(() => {
    onSearchChange({ searchParams });
  }, [onSearchChange, searchParams]);
  const { data: items = [], paginate } = data ?? {};
  const { data: workingBlock } = useQuery(['fetchWorkingBlocks'], () => categoryService.fetchWorkingBlocks());

  const handleSearch = (event: any) => {
    onSearchChange({ [searchType]: event.target.value })
  }
  const handleSearchType = (event: any) => {
    onSearchChange({ type: event.target.value === "all" ? "" : event.target.value })
  }
  const handleSearchDivision = (event: any) => {
    onSearchChange({ working_block_id: event.target.value === "all" ? "" : event.target.value })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      console.log(value)
      setSearchParams(value);
    }, 300),
    [],
  );
  return (
    <div className='mt-4'>
      <div className='flex items-end justify-between'>
        <Typography variant='h4'>
          <Trans>List of Employee</Trans>
        </Typography>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value="name">
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value="erc_number">
              <Trans>ID No.</Trans>
            </MenuItem>
            <MenuItem value="employee_code">
              <Trans>Staff ID</Trans>
            </MenuItem>
            <MenuItem value="type">
              <Trans>Type</Trans>
            </MenuItem>
            <MenuItem value="division">
              <Trans>Division</Trans>
            </MenuItem>
          </TextField>
          {
            searchType === "type" ?
              <TextField select defaultValue="all" onChange={(value) => { handleSearchType(value) }}>
                <MenuItem value="all">
                  <Trans>All</Trans>
                </MenuItem>
                <MenuItem value="FULL">
                  <Trans>Full-time</Trans>
                </MenuItem>
                <MenuItem value="SEASON">
                  <Trans>Part-time</Trans>
                </MenuItem>
              </TextField> : searchType === "division" ?
                <TextField select defaultValue="all" onChange={(value) => { handleSearchDivision(value) }}>
                  <MenuItem value="all">
                    <Trans>All</Trans>
                  </MenuItem>
                  {workingBlock?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                    </MenuItem>
                  ))}
                </TextField>
                : <TextField placeholder={t`Search...`} value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                    handleSearch(event);
                  }} />
          }

        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Staff ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Division</Trans>
              </TableCell>
              <TableCell>
                <Trans>Attendance Starting Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Attendance Closing Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>No. of Earned Labour</Trans>
              </TableCell>
              <TableCell>
                <Trans>Latest Updated on</Trans>
              </TableCell>
              <TableCell>
                <Trans>Advanced Labours</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.employeeId}>
                <TableCell>{item.employee.name}</TableCell>
                <TableCell>{item.employee.ercNumber}</TableCell>
                <TableCell>{item.employeeCode}</TableCell>
                <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.workingBlock?.viContent : item.workingBlock?.enContent}
                </TableCell>
                <TableCell>{item.fromDate ? DateTime.fromISO(item.fromDate).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.toDate ? DateTime.fromISO(item.toDate).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{formatNumber(item.totalLabor)}</TableCell>
                <TableCell>{item.updatedAt ? DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{formatNumber(item.advancedLabor)}</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default EmployeeList;
