import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Download, Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, fileService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ChecklistLegalType } from 'types/EnterpriseChecklist';
import { ObjectType } from 'types/Object';
import { download } from 'utils/common';
import { TableEmpty } from 'components';

type PopupProps = PopupController & {
  legal: ChecklistLegalType;
  enterpriseId: string;
};

const ChecklistStatusPopup = ({ legal, enterpriseId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ _id: enterpriseId, legalTypeId: legal.id });
  const [objects, setObjects] = useState<ObjectType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const { data } = useQuery(
    ['enterpriseService.fetchEnterpriseChecklistDoc', dataSearch],
    () => enterpriseService.fetchEnterpriseChecklistDoc(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: createEnterpriseChecklistDoc, isLoading } = useMutation(
    enterpriseService.createEnterpriseChecklistDoc,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseChecklistDoc');
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseChecklist');
        setObjects([]);
      },
    },
  );

  const handleClickSubmit = () => {
    createEnterpriseChecklistDoc({
      _id: enterpriseId,
      legalTypeId: legal.id,
      objectIds: objects.map((item) => item.id),
    });
  };

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects((items) => items.concat(data.objects));
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  return (
    <>
      <DialogTitle>
        <Trans>Review Legal</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <input ref={inputRef} type='file' 
          accept=".pdf,.docx,.doc,.xlsx,.xls,.png,.jpg,.jpeg,.mov,.mp4" 
          hidden multiple onChange={handleChangeFiles} />
          <LoadingButton
            variant='outlined'
            size='small'
            loading={isLoadingUpload}
            onClick={() => inputRef.current?.click()}
          >
            <Trans>Choose Files</Trans>
          </LoadingButton>

          <LoadingButton
            variant='contained'
            size='small'
            startIcon={<Upload />}
            disabled={objects.length === 0}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>

        <div className='flex flex-wrap gap-2 mt-2'>
          {objects.map((file) => (
            <Chip
              key={file.id}
              variant='outlined'
              label={file.originalName}
              onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
            />
          ))}
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>File</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Creator</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.object.originalName}</TableCell>
                  <TableCell>{item.createdUser.fullname}</TableCell>
                  <TableCell className='text-right'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        fileService.downloadFile(item.object.key).then((data) => {
                          download(data, item.object.originalName, item.object.contentType);
                        });
                      }}
                    >
                      <Download />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ChecklistStatusPopup;
