import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { enterpriseSetupService } from 'services';
import { TableEmpty } from 'components';
import { formatNumber } from 'utils/common';
import FeeNoticeView from './FeeNoticeView';
import { EnterpriseServiceFeeNoticeType } from 'types/EnterpriseSetup';
import { DateTime } from 'luxon';

const EnterpriseFee = () => {
  const [isOpen, setOpen] = useState(false);
  const [record, setRecord] = useState<EnterpriseServiceFeeNoticeType>();
  const [dataSearch, onSearchChange] = useSearch({
    statuses: 'COMPLETED',
  });

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEnterpriseFeeNotices', { enterpriseId: 0, ...dataSearch }],
    () => enterpriseSetupService.fetchEnterpriseFeeNotices({ enterpriseId: 0, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  return (
    <>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>List of Notice</Trans>
          </Typography>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Single Service Fee before tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Single Service Fee after tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Account</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, idx) => (
              <TableRow key={item.id}>
                <TableCell align='center'>{idx + 1}</TableCell>
                <TableCell align='center'>
                  {item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell align='right'>{formatNumber(item.feeBeforeTax)}</TableCell>
                <TableCell align='right'>{formatNumber(item.feeAfterTax)}</TableCell>
                <TableCell>
                  {item.bankAccountNumber} - {item.bankName} - {item.bankBranchName}
                </TableCell>
                <TableCell align='center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setRecord(item);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
      <Dialog fullWidth maxWidth='md' open={isOpen} onClose={() => setOpen(false)}>
        <FeeNoticeView noticeCreate={record!} onClose={() => setOpen(false)} />
      </Dialog>
    </>
  );
};

export default EnterpriseFee;
