import { UploadFileType } from 'types/Object';
import { EmployeeLaborPaginateType, EmployeeLaborUpdateType } from 'types/EmployeeLabor';
import { clientUpload, client } from './axios';
import { EmployeeSetupParamsType } from 'types/EmployeeSetup';

const uploadFileSystem = (body: FormData): Promise<UploadFileType> =>
  clientUpload.post(`/api/v1/employee-labors/import/system`, body);

const uploadFileEnterprise = (body: FormData): Promise<UploadFileType> =>
  clientUpload.post(`/api/v1/employee-labors/import/enterprise`, body);

const addOrUpdateEmployeeLabor = (body: EmployeeLaborUpdateType) => client.post(`/api/v1/employee-labors`, body);
const fetchEmployeeLabors = (params?: EmployeeSetupParamsType): Promise<EmployeeLaborPaginateType> =>
  client.get(`/api/v1/employee-labors`, { params });

export default {
  uploadFileSystem,
  uploadFileEnterprise,
  addOrUpdateEmployeeLabor,
  fetchEmployeeLabors,
};
