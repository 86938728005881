import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseSetupService, templateService } from 'services';
import { downloadBase64 } from 'utils/common';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
const EnterpriseSetupFinance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requreUserPermission, ENTERPRISE_KHOITAO_THONGTINTAICHINH} = usePermission();
  const naviagate = useNavigate();
  const { id } = useParams() as { id: string };

  const [dataSearch, onSearchChange] = useSearch({ id });

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEnterpriseFinances', dataSearch],
    () => enterpriseSetupService.fetchEnterpriseFinances(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: createEnterpriseFinance, isLoading } = useMutation(enterpriseSetupService.createEnterpriseFinance, {
    onSuccess: (data) => {
      //enqueueSnackbar(t`Successful`);
      naviagate(privateRoute.enterpriseSetupFinanceView.url?.({ id, fnId: data.id })!);
    },
  });

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Financial</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div>
          { requreUserPermission([ENTERPRISE_KHOITAO_THONGTINTAICHINH]) &&
          <div className='flex justify-between items-center mb-4'>
            <LoadingButton
              variant='contained'
              className='w-28'
              loading={isLoading}
              onClick={() => createEnterpriseFinance({ id })}
            >
              <Trans>Create</Trans>
            </LoadingButton>
            <Button
              size='small'
              color='info'
              onClick={() => {
                templateService.downloadFileBase64('import-enterprise-finance').then((res) => {
                  downloadBase64(res);
                });
              }}
            >
              <Trans>Download sample of Financial Information</Trans>
            </Button>
          </div>
          }
          <Typography variant='h4'>
            <Trans>Financial List</Trans>
          </Typography>
        </div>
        
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Created At</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Created By</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Last Updated At</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Last Updated By</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Completed At</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Completed By</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='text-center'>
                    {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {item.createdUser.fullname} - {item.createdUser.position}
                  </TableCell>
                  <TableCell className='text-center'>
                    {DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {item.updatedUser.fullname} - {item.updatedUser.position}
                  </TableCell>
                  <TableCell className='text-center'>
                    {item.completedAt ? DateTime.fromISO(item.completedAt).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>
                    {item.completedUser ? item.completedUser.fullname + ' - ' + item.completedUser.position : ''}
                  </TableCell>
                  <TableCell className='text-center'>{item.status === 1 ? t`Not Completed` : t`Completed`}</TableCell>
                  <TableCell className='text-right'>
                    <Link to={privateRoute.enterpriseSetupFinanceView.url?.({ id, fnId: item.id })!}>
                      <Button size='small' color='info'>
                        View
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </div>
    </Paper>
  );
};

export default EnterpriseSetupFinance;
