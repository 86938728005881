import { t, Trans } from '@lingui/macro';

import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEnterpriseStatus } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseService, transactionService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { formatNumber } from 'utils/common';
import { ViewFile } from 'views/Transaction/TransPaying/TransNotify'
const GuaranteeNoticeList = () => {
  const [searchParams, setSearchParams] = useState({});

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<EnterpriseType[]>([]);
  const [isOpenView, setOpenView] = useState(false);
  const [objectKey, changeobjectKey] = useState('');

  const { data } = useQuery(
    ['transactionService.listGuaranteeNotice', dataSearch],
    () => transactionService.listGuaranteeNotice(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice No</Trans>
              </TableCell>
              <TableCell>
                <Trans>No. of transaction</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Account</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (

              <TableRow key={item.id}>
                <TableCell align='center' className='p-1'>
                    {(idx + 1) + ((paginate?.page ? paginate?.page : 1) - 1) * (paginate?.size ? paginate?.size : 10)}
                </TableCell>
                <TableCell align='center'>
                  {item.signedDate ? DateTime.fromISO(item.signedDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.noticeNo}</TableCell>
                <TableCell align='right'>{formatNumber(item.totalTransaction)}</TableCell>
                <TableCell align='right'>{formatNumber(item.collectionAmount)}</TableCell>
                <TableCell align='center'>
                  {item.paymentDate ? DateTime.fromISO(item.paymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  {item.bankAccountNumber} - {item.bankName} - {item.bankBranchName}
                </TableCell>
                <TableCell className='text-right'>
                    <Button size='small' color='info' onClick={()=>{ changeobjectKey(item.objectKey); setOpenView(true);}}>
                      <Trans>View</Trans>
                    </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>


      <Dialog fullWidth maxWidth='md' open={isOpenView} onClose={() => setOpenView(false)}>
        {<ViewFile
          keykhac={objectKey}
          title={'GUARANTEE NOTICE'}
          onClose={() => setOpenView(false)}
        />}
      </Dialog>
    </div >
  );
};

export default GuaranteeNoticeList;
