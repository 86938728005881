import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { promotionService } from 'services';
import { PaginateType, PopupController } from 'types/Common';
import { EmployeeOfEnterpriseType } from 'types/Promotion';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useState } from 'react';
import { PromotionEmployeeType } from 'types/Employee';
import { formatNumber } from 'utils/common';
import { getEmployeeType } from 'constants/employee';
import { useEffect } from 'react';
type PopupProps = PopupController & {
  promotionId: string;
  enterpriseId: number;
  numberApplied: number;
  dataSelect: EmployeeOfEnterpriseType[];
  setDataSelect: (data: any) => void;
};

const EmployeeListPopup = ({ promotionId, enterpriseId, setDataSelect, dataSelect, numberApplied, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  //const [items, setItems] = useState<PromotionEmployeeType[]>([]);
  // const [paginate, setPaginate] = useState<PaginateType>();
  const [checkall, changecheckall] = useState(false);
  const [dataSearch, onSearchChange] = useSearch({ id: promotionId, enId: enterpriseId });
  const { data } = useQuery(
    ['userService.fetchPromotionEmployees', dataSearch],
    () => promotionService.fetchPromotionEmployees(dataSearch),
    {
      keepPreviousData: true,
      onSuccess(data) {
        if (data) {
          if (data?.employeeIds && data.employeeIds.length > 0) {
            if (enterpriseIndex === -1) {
              const employees = data.employeeIds.map((item) => {
                return { id: item };
              });
              dataSelect.push({ id: enterpriseId, employees });
              const newData = [...dataSelect];
              setDataSelect(newData);
            }
          }
        }
      },
    },
  );
  const { data: items = [], paginate } = data ?? {};

  const enterpriseIndex = dataSelect.findIndex((ele: EmployeeOfEnterpriseType) => ele.id === enterpriseId);

  function selectAll(){
    promotionService.fetchPromotionAllEmployees({ id: promotionId, enId: enterpriseId }).then((data : any)=>{
      const employees = data.map((item:number) => {
        return { id: item };
      });
      if (enterpriseIndex !== -1) {
        dataSelect[enterpriseIndex].employees = employees;
        const newData = [...dataSelect];
        setDataSelect(newData);
      } else {
        dataSelect.push({ id: enterpriseId, employees: employees });
        const newData = [...dataSelect];
        setDataSelect(newData);
      }
    })
  }
  function deselectAll(){
    dataSelect = dataSelect.filter(function(x){
      return x.id != enterpriseId;
    })
    const newData = [...dataSelect];
    setDataSelect(newData);
  }

  useEffect(() => {
    var page = paginate?.totalRecord;
    var ds = dataSelect.filter((x:any)=>{ return x.id === enterpriseId });
    if(ds.length > 0) {
      if(ds[0].employees.length == page) {
        changecheckall(true);
      }else{
        changecheckall(false);
      }
    }else{
      changecheckall(false);
    }
  }, [dataSelect]);


  return (
    <>
      <DialogTitle>
        <Trans>Employee List</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                <Checkbox
                      checked={ checkall }
                      onChange={(event, checked) => {
                        if(checked == true){
                          selectAll();
                        }else{
                          deselectAll();
                        }
                      }}
                    />
                </TableCell>
                <TableCell>
                  <Trans>Employee Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Division</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Remaining time to get Promotion</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={promotionId + "_" + enterpriseId + "_" + item.id + "_" + item.employeeType}>
                  <TableCell>
                    <Checkbox
                      checked={
                        enterpriseIndex !== -1 &&
                        dataSelect[enterpriseIndex].employees.findIndex((ele) => ele.id === item.id) !== -1
                      }
                      onChange={(event, checked) => {
                        if (checked) {
                          if (enterpriseIndex !== -1) {
                            dataSelect[enterpriseIndex].employees.push({ id: item.id });
                            const newData = [...dataSelect];
                            setDataSelect(newData);
                          } else {
                            dataSelect.push({ id: enterpriseId, employees: [{ id: item.id }] });
                            const newData = [...dataSelect];
                            setDataSelect(newData);
                          }
                        } else {
                          if (enterpriseIndex !== -1) {
                            dataSelect[enterpriseIndex].employees = dataSelect[enterpriseIndex].employees.filter(
                              (employee: any) => employee.id !== item.id,
                            );
                            if (dataSelect[enterpriseIndex].employees.length === 0) {
                              const newData = dataSelect.filter((next) => next.id !== enterpriseId);
                              newData.push({ id: enterpriseId, employees: [] });
                              setDataSelect(newData);
                            } else {
                              const newData = [...dataSelect];
                              setDataSelect(newData);
                            }
                          }
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.employeeName}</TableCell>
                  <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                  <TableCell> {userLocale === 'vi-VN' ? item.employeeDivisionVi : item.employeeDivisionEn}</TableCell>
                  <TableCell align='right'>
                    { 
                     (enterpriseIndex !== -1 && dataSelect[enterpriseIndex].employees.findIndex((ele) => ele.id === item.id) !== -1
                      ) ? item.getPromotion === 'YES' ? formatNumber(item.numberAppliedTimes) : formatNumber(numberApplied) : ''}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EmployeeListPopup;
