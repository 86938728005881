import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { employeeService, queryClient } from 'services';
import { ContractType } from 'types/EmployeeContract';
import { TableEmpty } from 'components';
import {
  CompletedState,
  getContractName,
  getContractStateForEnterprise,
  OutdatedState,
  WaitingPartnerState,
} from 'constants/contract';
import { DateTime } from 'luxon';
import ContractViewPopup from './ContractViewPopup';
import ContractRejectPopup from './ContractRejectPopup';
import OtpInput from 'react-otp-input-rc-17';
import { LoadingButton } from '@mui/lab';

const ContractListEmployee = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery(
    [
      'employeeService.fetchEmployeeContract',
      { _id: '0', states: `${WaitingPartnerState},${CompletedState},${OutdatedState}` },
    ],
    () =>
      employeeService.fetchEmployeeContract({
        _id: '0',
        states: `${WaitingPartnerState},${CompletedState},${OutdatedState}`,
      }),
  );
  const { data: items = [] } = data ?? {};

  const [isOpen, setOpen] = useState(false);
  const [isOpenOtp, setOpenOtp] = useState(false);
  const [isOpenReject, setOpenReject] = useState(false);
  const [otp, setOtp] = useState('');
  const [chosenContract, setChosenContract] = useState<ContractType>();

  const { mutate: sendOtp } = useMutation(employeeService.sendEmployeeContractOtp, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setOpenOtp(true);
    },
  });

  const { mutate: signContract, isLoading: isLoadingSign } = useMutation(employeeService.approveEmployeeContract, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.fetchEmployeeContract');
      setOpenOtp(false);
    },
  });

  const handleSendOtp = (item: ContractType) => {
    setChosenContract(item);
    sendOtp({ _id: '0', id: item.id });
  };

  const handleSignContract = () => {
    if (chosenContract) {
      signContract({ _id: '0', id: chosenContract?.id!, otp });
    }
  };

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Contract List</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Signing Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{getContractStateForEnterprise(item.state)}</TableCell>
                <TableCell>{getContractName(item.type)}</TableCell>
                <TableCell>{item.contractNo}</TableCell>
                <TableCell>{item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell align='center'>
                  {item.state === 'WAITING_PARTNER' ? (
                    <>
                      <Button
                        size='small'
                        color='error'
                        onClick={() => {
                          setChosenContract(item);
                          setOpenReject(true);
                        }}
                      >
                        <Trans>Reject</Trans>
                      </Button>
                      <Button
                        size='small'
                        color='info'
                        onClick={() => {
                          handleSendOtp(item);
                        }}
                      >
                        <Trans>Sign</Trans>
                      </Button>
                    </>
                  ) : (
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpen(true);
                        setChosenContract(item);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={() => setOpen(false)}>
        <ContractViewPopup contract={chosenContract!} employeeId='0' onClose={() => setOpen(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={isOpenOtp} onClose={() => setOpenOtp(false)}>
        <DialogTitle>
          <Trans>Sign Contract</Trans>
        </DialogTitle>

        <DialogContent>
          <div className='flex justify-center items-center'>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle={{
                marginLeft: '16px',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                width: '48px',
                height: '48px',
                borderRadius: 4,
              }}
            />
          </div>
        </DialogContent>

        <DialogActions className='relative'>
          <LoadingButton variant='outlined' onClick={() => setOpenOtp(false)} className='absolute left-4'>
            <Trans>Close</Trans>
          </LoadingButton>

          <LoadingButton variant='contained' className='w-28' loading={isLoadingSign} onClick={handleSignContract}>
            <Trans>Sign</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {chosenContract && (
        <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
          <ContractRejectPopup
            contract={chosenContract!}
            employeeId='0'
            onClose={() => {
              setOpenReject(false);
            }}
          />
        </Dialog>
      )}
    </div>
  );
};

export default ContractListEmployee;
