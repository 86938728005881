import { Trans, t } from '@lingui/macro';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  Grid,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { useSearch } from 'hooks';
import { formatNumber } from 'utils/common';
import { getEnterpriseApplyFeeType, getEnterpriseServiceFeeHistoryStatusType } from 'constants/enterprise';
import { EnterpriseSettingFeePopup } from 'views/Enterprise/EnterpriseServiceFee/popups';

const ContractServiceFee = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data: contractServiceFeeInfo } = useQuery(['enterpriseService.getEnterpriseServiceFeeApplyInfo'], () =>
    enterpriseSetupService.getEnterpriseServiceFeeApplyInfo({ id: 0 }),
  );

  const { data } = useQuery(['enterpriseSetupService.fetchEnterpriseServiceFeeHistories', dataSearch], () =>
    enterpriseSetupService.fetchEnterpriseServiceFeeHistories({ id: 0, ...dataSearch }),
  );

  const { data: items = [], paginate } = data ?? {};

  const [openPopup, setOpenPopup] = useState(false);
  const [dataItem, setDataItem] = useState<any>();
  const [stepCurrent, setStepCurrent] = useState<number>();

  return (
    <>
      <div className='mt-6'>
        <Typography variant='h4'>
          <Trans>Enterprise's Service Fee</Trans>
        </Typography>
        <div className='my-4'>
          <Grid container columnSpacing={4} rowSpacing={3}>
            <Grid item sm={3}>
              <TextField
                fullWidth
                label={t`Applied Type`}
                value={getEnterpriseApplyFeeType(contractServiceFeeInfo?.appliedType) ?? ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={3}>
              <TextField
                fullWidth
                label={t`Fixed Service Fee (VND)`}
                value={formatNumber(contractServiceFeeInfo?.fixedFee) ?? 0}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={3}>
              <TextField
                fullWidth
                label={t`Expire Date`}
                value={contractServiceFeeInfo?.endValidityTime ? DateTime.fromISO(contractServiceFeeInfo?.endValidityTime).toFormat('dd/MM/yyyy') : ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </div>

        <Typography variant='h4'>
          <Trans>Confirmation History</Trans>
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Execution Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Applied Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Fixed Service Fee (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Expire Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='text-center'>
                    {item.createdAt ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell className='text-center'>{getEnterpriseApplyFeeType(item.appliedType)}</TableCell>
                  <TableCell className='text-center'>{formatNumber(item.fixedFee)}</TableCell>
                  <TableCell className='text-center'>
                    {item.endValidityTime ? DateTime.fromISO(item.endValidityTime).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>{getEnterpriseServiceFeeHistoryStatusType(item.status)}</TableCell>
                  <TableCell className='text-center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenPopup(true);
                        setDataItem(item);
                        if (item.appliedType === 'FIXED_SERVICE_FEE') {
                          if (item.status === 'WAITING_FOR_APPROVE') setStepCurrent(4);
                          else if (
                            item.status === 'WAITING_FOR_SIGNING' ||
                            item.status === 'WAITING_FOR_ENT_CONFIRMATION'
                          )
                            setStepCurrent(5);
                          else if (
                            item.status === 'COMPLETED' ||
                            item.status === 'REJECTED' ||
                            item.status === 'CANCELED'
                          )
                            setStepCurrent(7);
                          else setStepCurrent(1);
                        } else {
                          if (item.status === 'WAITING_FOR_ENT_CONFIRMATION') setStepCurrent(3);
                          else if (item.status === 'COMPLETED' || item.status === 'REJECTED') setStepCurrent(7);
                        }
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='lg' open={openPopup} onClose={() => setOpenPopup(false)}>
        <EnterpriseSettingFeePopup
          dataItem={dataItem}
          stepCurrent={stepCurrent}
          enterpriseId={dataItem?.enterpriseId}
          onClose={() => setOpenPopup(false)}
        />
      </Dialog>
    </>
  );
};

export default ContractServiceFee;
