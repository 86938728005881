import { t, Trans } from '@lingui/macro';
import {
  Button,
  debounce,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEnterpriseStatus } from 'constants/enterprise';
import { useCallback, useEffect, useState } from 'react';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseService } from 'services';
import { formatNumber } from 'utils/common';
import { TopCalendar } from 'views/Dashboard/Main/components';

const EnterpriseOnBoard = () => {
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [dataSearch, onSearchChange] = useSearch({ statuses: 'ONBOARDED,STOP_CORPORATION', sum: 1 });

  const { data } = useQuery(
    ['enterpriseService.fetchEnterprises', dataSearch],
    () => enterpriseService.fetchEnterprises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <Grid container columnSpacing={4} rowSpacing={1} className='mb-6'>
        <Grid item sm={12}>
          <TopCalendar limit={1} />
        </Grid>
      </Grid>

      <div className='flex justify-end'>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='name'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value='taxCode'>
              <Trans>Tax Code</Trans>
            </MenuItem>
            <MenuItem value='locationName'>
              <Trans>Location</Trans>
            </MenuItem>
            <MenuItem value='seniorCrmName'>
              <Trans>Senior CRM</Trans>
            </MenuItem>
            <MenuItem value='status'>
              <Trans>Status</Trans>
            </MenuItem>
          </TextField>
          {['name', 'taxCode', 'locationName', 'seniorCrmName'].includes(searchType) && (
            <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            />
          )}
          {['status'].includes(searchType) && (
            <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              <MenuItem value='ONBOARDED'>
                <Trans>ONBOARDED</Trans>
              </MenuItem>
              <MenuItem value='STOP_CORPORATION'>
                <Trans>STOP_CORPORATION</Trans>
              </MenuItem>
            </TextField>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Tax Code</Trans>
              </TableCell>
              <TableCell>
                <Trans>Location</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Discount Rate (APR)</Trans>
              </TableCell>
              <TableCell>
                <Trans>No. of On-boarded Employee</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Advance Payment (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Service Fee (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Senior CRM</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.taxCode}</TableCell>
                <TableCell>{item.locationName}</TableCell>
                <TableCell>{formatNumber(item.totalLimit)}</TableCell>
                <TableCell>
                  {formatNumber(item.earnedDiscountRate)}% - {formatNumber(item.futureDiscountRate)}%
                </TableCell>
                <TableCell>
                  {formatNumber(item.noOfOnboardedEmployee)}/{formatNumber(item.noOfEmployee)}
                </TableCell>
                <TableCell>{formatNumber(item.totalAdvancedPayment)}</TableCell>
                <TableCell>{formatNumber(item.totalServiceFee)}</TableCell>
                <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
                <TableCell>{item.seniorUser?.fullname}</TableCell>
                <TableCell className='text-right'>
                  <Link to={privateRoute.enterpriseClientView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default EnterpriseOnBoard;
