import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { userService } from 'services';
import { UserCreateType, UserType } from 'types/User';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
function EnterpriseTransactionRepresentative() {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams() as { id: string };

  const [open, setOpen] = useState(false);
  const { control, handleSubmit, getValues, reset } = useForm({ mode: 'onChange' });
  const [loading, setLoading] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState<null | number>(null);
  const { requireUserPCManagerOrCRMLeader } = usePermission();
  const [paginate, setPaginate] = useState({
    page: 1,
    size: 5,
    totalPage: 1,
    totalRecord: 0,
  });

  const [users, setUsers] = useState<UserType[]>([]);
  const [userToChangeStatus, setUserToChangeStatus] = useState<null | number>(null);
  const [err, setErr] = useState('');

  const [createUserSuccess, setCreateUserSuccess] = useState(false);
  useEffect(() => {
    if (deletedUserId === null && userToChangeStatus === null)
      userService.fetchUsers({ enterpriseId: id, page: paginate.page, size: paginate.size }).then((res) => {
        setUsers(res.data);
        setPaginate((prev) => ({
          ...prev,
          totalPage: res.paginate.totalPage,
        }));
      });
  }, [id, paginate.page, paginate.size, deletedUserId, userToChangeStatus, createUserSuccess]);
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleClickSubmit = () => {
    if (createUserSuccess) {
      enqueueSnackbar(t`Successful`);
      setOpen(false);
      setCreateUserSuccess(false);
      reset();
      return;
    }
    handleSubmit(async (values: UserCreateType) => {
      console.log(values);
      if (values.fullname.trim().length == 0) {
        enqueueSnackbar("Tên không được để trống", { variant: 'error' });
        return;
      }
      if (values.email.trim().length == 0) {
        enqueueSnackbar("Email không được để trống", { variant: 'error' });
        return;
      } else {
        if (!isValidEmail(values.email.trim())) {
          enqueueSnackbar(t`Email is not valid`, { variant: 'error' });
          return;
        }
      }
      if (values.phone.trim().length == 0) {
        enqueueSnackbar("SĐT không được để trống", { variant: 'error' });
        return;
      }
      setLoading(true);
      await userService
        .createUser({
          ...values,
          enterpriseId: Number(id),
        })
        .then(() => {
          enqueueSnackbar(t`Successful`);
          setLoading(false);
          setErr('');
          setCreateUserSuccess(true);
        })
        .catch((e) => {
          setErr(e?.response?.data?.errorMessage || t`Somethings went wrong, please try again later`);
          setLoading(false);
        });
    })();
  };

  const handleDeleteUser = () => {
    if (userToChangeStatus) {
      const user = users.find((item) => item.id === userToChangeStatus);
      if (!user) return;

      setLoading(true);

      userService
        .updateStatus({ id: userToChangeStatus as number, status: user.status === 'active' ? 'inactive' : 'active' })
        .then(() => {
          enqueueSnackbar(t`Successful`);
          setLoading(false);
          setUserToChangeStatus(null);
        });

      return;
    }

    if (!deletedUserId) return;
    setLoading(true);
    userService.removeUser({ id: deletedUserId }).then(() => {
      enqueueSnackbar(t`Successful`);
      setLoading(false);
      setDeletedUserId(null);
    });
  };

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction Representative Information</Trans>
          </Typography>
          {requireUserPCManagerOrCRMLeader() &&
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                setOpen(true);
                // setChosenUpdate(undefined);
              }}
            >
              <Trans>Add Account</Trans>
            </Button>
          }
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className='text-left'>
                <Trans>Name</Trans>
              </TableCell>
              <TableCell className='text-left'>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell className='text-left'>Email</TableCell>
              <TableCell className='text-left'>
                <Trans>Phone</Trans>
              </TableCell>
              <TableCell className='text-right'>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.fullname}</TableCell>
                <TableCell>{user.position}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell className='text-right'>
                  {(() => {
                    if (user.status === 'invited')
                      return (
                        <div>
                          <Trans>Invited</Trans>
                        </div>
                      );

                    if (user.status === 'active')
                      return (
                        <Switch
                          checked={user.status === 'active'}
                          onClick={() => {
                            setUserToChangeStatus(user.id);
                          }}
                        />
                      );
                    if (user.status === 'inactive')
                      return <Switch checked={false} onClick={() => setUserToChangeStatus(user.id)} />;
                  })()}
                </TableCell>
                <TableCell className='text-right'>
                  {user.status !== 'active' && requireUserPCManagerOrCRMLeader() && (
                    <Button variant='text' onClick={() => setDeletedUserId(user.id)}>
                      <Trans>Delete</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={users} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => setPaginate({ ...paginate, page: value })}
        />
      </div>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={!!deletedUserId || !!userToChangeStatus}
        onClose={() => {
          setDeletedUserId(null);
          setUserToChangeStatus(null);
        }}
      >
        <DialogTitle>
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          {userToChangeStatus ? (
            <Trans>
              <Trans>
                Do you want to{' '}
                {users.find((user) => user.id === userToChangeStatus)?.status === 'active' ? t`deactive` : t`active`}{' '}
                user {users.find((user) => user.id === userToChangeStatus)?.fullname}?
              </Trans>
            </Trans>
          ) : (
            <Trans>Do you want to delete user {users.find((user) => user.id === deletedUserId)?.fullname}?</Trans>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={() => {
              setDeletedUserId(null);
              setUserToChangeStatus(null);
            }}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={loading} variant='contained' onClick={handleDeleteUser}>
            {userToChangeStatus ? <Trans>Update</Trans> : <Trans>Delete</Trans>}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={open}
        onClose={() => {
          if (createUserSuccess) {
            reset();
            setCreateUserSuccess(false);
          }
          setOpen(false);
        }}
      >
        <DialogTitle>
          <Trans>Add Account</Trans>
        </DialogTitle>
        <DialogContent>
          {createUserSuccess ? (
            <div><Trans>Create user successfully. An activation email was sent to {getValues('email')}</Trans></div>
          ) : (
            <>
              <Grid container columnSpacing={2} rowSpacing={3}>
                <Grid item sm={12}>
                  <Controller
                    name='fullname'
                    defaultValue=''
                    control={control}
                    // rules={{ required: t`Name is required` }}
                    rules={{
                      validate: (e) => {
                        if (!e || e.trim() == "") {
                          return t`Name is required`;
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        label={t`Name`}
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    name='position'
                    defaultValue=''
                    control={control}
                    rules={{ required: false }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField {...field} fullWidth label={t`Position`} error={invalid} helperText={error?.message} />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    name='email'
                    defaultValue=''
                    control={control}
                    // rules={{ required: t`Email is required` }}
                    rules={{
                      validate: (e) => {
                        if (!e || e.trim() == "") {
                          return t`Email is required`;
                        }
                        if (!e.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                          return t`Email is not valid`;
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        label={t`Email`}
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    name='phone'
                    defaultValue=''
                    control={control}
                    // rules={{ required: t`Phone is required` }}
                    rules={{
                      validate: (e) => {
                        if (!e || e.trim() == "") {
                          return t`Phone is required`;
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        label={t`Phone`}
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <div className='mt-2 text-red-500'>{err}</div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton variant='outlined' onClick={() => setOpen(false)}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={loading} variant='contained' className='w-20' onClick={handleClickSubmit}>
            {createUserSuccess ? 'OK' : <Trans>Save</Trans>}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EnterpriseTransactionRepresentative;
