import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from '@mui/material';
import { TableEmpty } from 'components';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { EnterpriseGuaranteeType } from 'types/EnterpriseSetup';
import { GuaranteeConfirmationPopup } from 'views/ClientSetup/Enterprise/popups';
import { useSearch } from 'hooks';
import { getGuaranteeStatusName, getGuaranteeStatusType } from 'constants/enterprise';

const ContractGuaranteeStatus = () => {
  const [dataSearch, onSearchChange] = useSearch();

  const { data } = useQuery(['enterpriseService.getEnterpriseGuarantee', dataSearch], () =>
    enterpriseSetupService.getEnterpriseGuarantee(dataSearch),
  );

  const { data: items = [], paginate } = data ?? {};
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState<EnterpriseGuaranteeType>();
  const [stateCurrent, setStateCurrent] = useState('');
  return (
    <>
      <div className='mt-6'>
        <Typography variant='h4'>
          <Trans>Guarantee Status</Trans>
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Notice Day</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Guarantee Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Confirmation Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Notice</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                .sort((a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis())
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className='text-center'>
                      {item.createdAt ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy') : ''}
                    </TableCell>
                    <TableCell className='text-center'>{getGuaranteeStatusName(item.state)}</TableCell>
                    <TableCell className='text-center'>{getGuaranteeStatusType(item.status)}</TableCell>
                    <TableCell className='text-center'>
                      {item.state != "NON_GUARANTEE" ? <Button
                        size='small'
                        color='info'
                        onClick={() => {
                          setSelectedNotice(item);
                          setOpenPopup(true);
                          setStateCurrent(item.status);
                        }}
                      >
                        <Trans>View</Trans>
                      </Button> : ""}
                    </TableCell>
                  </TableRow>
                ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={openPopup} onClose={() => setOpenPopup(false)}>
        <GuaranteeConfirmationPopup
          stateCurrent={stateCurrent}
          notice={selectedNotice!}
          onClose={() => setOpenPopup(false)}
        />
      </Dialog>
    </>
  );
};

export default ContractGuaranteeStatus;
