import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService, enterpriseSetupService } from 'services';
import {
  EnterpriseAdvanceLabor,
  EnterpriseAdvanceLevel,
  EnterpriseAdvanceSeniority, EnterpriseBusinessCredit, EnterpriseEmployee, EnterpriseFullTime, EnterpriseFullTimeAdvance, EnterpriseGuaranteeConfirmation, EnterpriseInfo,
  EnterpriseOverdueDays,
  EnterprisePartTime,
  EnterpriseWorkingBlocks
} from 'views/ClientSetup/Enterprise/components';

const EnterpriseSetupView = () => {
  const { id: _id } = useParams() as { id: string };

  const { data: enterprise, isFetched } = useQuery(['enterpriseService.getEnterpriseById', { id: _id }], () =>
    enterpriseService.getEnterpriseById({ id: _id }),
  );
  const { data: setting, isFetched: isFetchedSetting } = useQuery(
    ['enterpriseSetupService.getGeneralSettings', { id: _id }],
    () => enterpriseSetupService.getGeneralSettings({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Setup Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <EnterpriseInfo enterprise={enterprise} />

        {isFetched && isFetchedSetting && (
          <Grid container columnSpacing={4} rowSpacing={2} className='mt-4'>
            <Grid item sm={12}>
              <EnterpriseBusinessCredit enterprise={enterprise} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseAdvanceLabor enterprise={enterprise} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseAdvanceLevel enterprise={enterprise} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseAdvanceSeniority enterprise={enterprise} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseGuaranteeConfirmation enterpriseId={_id} />
            </Grid>
            <Grid item sm={12}>
              <EnterpriseWorkingBlocks enterprise={enterprise} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseOverdueDays setting={setting} />
            </Grid>
            <Grid item sm={6}>
              <EnterprisePartTime setting={setting} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseFullTime setting={setting} />
            </Grid>
            <Grid item sm={6}>
              <EnterpriseFullTimeAdvance setting={setting} />
            </Grid>
            <Grid item sm={12}>
              <EnterpriseEmployee />
            </Grid>
          </Grid>
        )}
      </div>
    </Paper>
  );
};

export default EnterpriseSetupView;
