import { Trans, t } from '@lingui/macro';
import { DesktopDatePicker } from '@mui/lab';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { DateTime } from 'luxon';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { formatNumber } from 'utils/common';
import { useState } from 'react';
import { PaymentSlipPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';

const EnterprisePaidPaymentList = ({ id }: { id: string }) => {
  const { control, watch, clearErrors } = useForm({ mode: 'onChange' });
  const [dataSearch, onSearchChange] = useSearch();
  const [openPaySlip, setOpenPaySlip] = useState(false);
  const [feePaymentId, setFeePaymentId] = useState<number>();

  const { data } = useQuery(
    ['enterpriseSetupService.getEnterprisePaidPaymentData', { enterpriseId: id, ...dataSearch }],
    () => enterpriseSetupService.getEnterprisePaidPaymentData({ enterpriseId: id, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  const { fromDate, toDate } = watch();

  return (
    <>
      <div className='flex items-center justify-end'>
        <div className='space-x-4'>
          <Controller
            name='fromDate'
            defaultValue={DateTime.now()}
            control={control}
            rules={{
              validate: {
                isValid: (value: DateTime) => value && value.isValid,
                isAfter: (value) =>
                  DateTime.fromISO(value) < DateTime.fromISO(toDate) || 'From Date should be before the To Date',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (DateTime.fromISO(value) < DateTime.fromISO(toDate)) {
                    clearErrors(['fromDate', 'toDate']);
                    onSearchChange({ fromDate: value.toISO(), toDate: toDate.toISO() });
                  }
                }}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`From Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
          <Controller
            name='toDate'
            defaultValue={DateTime.now()}
            control={control}
            rules={{
              validate: {
                isValid: (value: DateTime) => value && value.isValid,
                isAfter: (value) =>
                  DateTime.fromISO(value) > DateTime.fromISO(fromDate) || 'To Date should be after the From Date',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={(value) => {
                  onChange(value);
                  if (DateTime.fromISO(value) > DateTime.fromISO(fromDate)) {
                    clearErrors(['fromDate', 'toDate']);
                    onSearchChange({ fromDate: fromDate.toISO(), toDate: value.toISO() });
                  }
                }}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`To Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Category</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee before tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee after tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Paid Amount of Late Payment (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Slip</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  {item.paymentDate ? DateTime.fromISO(item.paymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  <Trans>{getEnterpriseApplyFeeType(item.category)}</Trans>
                </TableCell>
                <TableCell>{item.transactionCode}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeBeforeTax)}</TableCell>
                <TableCell align='right'>{formatNumber(item.serviceFeeAfterTax)}</TableCell>
                <TableCell align='right'>{formatNumber(item.paidAmountOfLatePayment)}</TableCell>
                <TableCell align='right'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setFeePaymentId(item.id);
                      setOpenPaySlip(true);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={openPaySlip} onClose={() => setOpenPaySlip(false)}>
        <PaymentSlipPopup enterpriseId={id} feePaymentId={feePaymentId!} onClose={() => setOpenPaySlip(false)} />
      </Dialog>
    </>
  );
};

export default EnterprisePaidPaymentList;
