import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { transactionService } from 'services';
import { TransReviseType, TransType } from 'types/Transaction';
import { TransReviseNoticePopup } from 'views/Transaction/TransSettlement/TransSettlementView/popups';
import { TableEmpty } from 'components';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  transaction: TransType;
};

const TransReviseHisPopup = ({ transaction, onClose }: PopupProps) => {
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [record, setRecord] = useState<TransReviseType>();
  const [dataSearch, onSearchChange] = useSearch({ transId: transaction?.id });

  const { data } = useQuery(
    ['transactionService.fetchTransactionRevises', dataSearch],
    () => transactionService.fetchTransactionRevises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <>
      <DialogTitle>
        <Trans>Revision Notice</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Notice No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Detail</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell align='center'>{idx + 1}</TableCell>
                  <TableCell>{item.adjustNotice?.code}</TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setRecord(item);
                        setOpenNotice(true);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
      <Dialog fullWidth maxWidth='lg' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
        <TransReviseNoticePopup
          transactionId={transaction?.id!}
          transactionReviseId={record?.id!}
          onClose={() => setOpenNotice(false)}
        />
      </Dialog>
    </>
  );
};

export default TransReviseHisPopup;
