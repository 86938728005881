import { Trans } from '@lingui/macro';
import { Link as MuiLink, AppBar, Container, Toolbar, Typography, List, ListItemButton, Button } from '@mui/material';
import classNames from 'classnames';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setUserLocale } from 'reducers/user-settings';
import { epayzRoute } from 'routes';

const HeaderEpayz = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userLocale = useActiveLocale();

  // const { offer, salaryAdvance, brokerageAdvance, forBusiness, partners, aboutUs, login } = epayzRoute;
  const { offer, salaryAdvance, brokerageAdvance, partners, aboutUs, login } = epayzRoute;

  return (
    <AppBar>
      <Toolbar className='min-h-[44px]'>
        <Container className='flex justify-between'>
          <Typography>Hotline: 1900 1212 11</Typography>
          <Typography>{ userLocale === 'vi-VN' ? 'Công ty TNHH Nền tảng Số IFZ' : 'IFZ Digital Platform Co., Ltd'} (0109830986)</Typography>
        </Container>
      </Toolbar>
      <Toolbar className='bg-white/90'>
        <Container className='flex items-center'>
          <Link to='/'>
            <img src={require('assets/images/Logo_IFZ.png')} className='h-16' />
          </Link>
          <div className='flex-1' />
          {/* <Link to={offer.path}>
            <div className='flex items-center w-max mr-4 hover:opacity-80'>
              <img src={require('assets/epayz/limited_offer.png')} className='h-20 -my-4' />
            </div>
          </Link> */}
          <List className='flex'>
            {/* {[salaryAdvance, brokerageAdvance, forBusiness, partners, aboutUs].map((item, index) => ( */}
            {[salaryAdvance, brokerageAdvance, partners, aboutUs].map((item, index) => (
              <Link key={index} to={item.path}>
                <ListItemButton
                  className={classNames('hover:text-primary-main', {
                    'text-black': !location.pathname.startsWith(item.path),
                    'text-primary-main': location.pathname.startsWith(item.path),
                  })}
                >
                  {item.name}
                </ListItemButton>
              </Link>
            ))}
          </List>

          <Link to={login.path}>
            <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90 mx-6'>
              <Trans>Login</Trans>
            </Button>
          </Link>

          <div className='ml-4 mr-12'>
            {[
              { locale: 'en-US', label: 'EN' },
              { locale: 'vi-VN', label: 'VN' },
            ].map((item, index) => (
              <Fragment key={index}>
                <MuiLink
                  className={classNames(
                    'hover:text-primary-main cursor-pointer font-bold no-underline',
                    { 'text-black/60': userLocale !== item.locale },
                    { 'text-primary-main': userLocale === item.locale },
                  )}
                  onClick={() => dispatch(setUserLocale(item.locale))}
                >
                  {item.label}
                </MuiLink>
                {index === 0 && <span className='mx-1.5'>|</span>}
              </Fragment>
            ))}
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderEpayz;
