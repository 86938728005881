import {
  EmployeePaginateType,
  EmployeeSetupParamsType,
  EmployeeSetupBasicInfo,
  EmployeeSetupPayrollParamsType,
  EmployeeSetupCondition,
  EmployeeSalaryCondition,
  EmployeePenalRate,
  UpdateEmployeePenalRateType,
  EmployeeSetupPayrollPaginateType,
  UpdateOrRemoveBlacklistType,
  EmployeeSetupLabourDetail,
  EmployeeSetupPayrollAdvanceSetting,
  UpdatePayrollAdvanceSettingType,
  UpdateStatusEmployeeType,
  SupportProgramPaginateType,
  SupportProgramHisPaginateType,
  CreateSupportProgramType,
  UpdateSupportProgramType,
  SupportProgramType,
  SearchSupportProgramHisType,
  EmployeeEkycListType,
} from 'types/EmployeeSetup';
import { client } from './axios';

const fetchEmployeeSetup = (params?: EmployeeSetupParamsType): Promise<EmployeePaginateType> =>
  client.get(`/api/v1/employee-setup`, { params });

const fetchEmployeeBasicInfo = ({ id }: { id: string }): Promise<EmployeeSetupBasicInfo> =>
  client.get(`/api/v1/employee-setup/${id}/basic-info`);

const getPayrollAdvanceSetting = ({
  id,
  ...params
}: EmployeeSetupPayrollParamsType): Promise<EmployeeSetupPayrollPaginateType> =>
  client.get(`/api/v1/employee-setup/${id}/payroll-advance-setting`, { params });

const getSalaryCondition = ({ id }: { id: string }): Promise<EmployeeSalaryCondition[]> =>
  client.get(`/api/v1/employee-setup/${id}/enterprises`);

const getExpectedPenalRate = ({ id }: { id: string }): Promise<EmployeePenalRate> =>
  client.get(`/api/v1/employee-setup/${id}/expected-penal-rate`);

const updateExpectedPenalRate = ({ id, ...body }: UpdateEmployeePenalRateType): Promise<EmployeePenalRate> =>
  client.put(`/api/v1/employee-setup/${id}/expected-penal-rate`, body);

const updateOrRemoveBlacklist = ({ employeeId, ...body }: UpdateOrRemoveBlacklistType) =>
  client.put(`/api/v1/employee-setup/${employeeId}/blacklist`, body);

const updateStatusEmployee = ({ employeeId, enterpriseEmployeeId, ...body }: UpdateStatusEmployeeType) =>
  client.put(`/api/v1/employee-setup/${employeeId}/enterprise-employee/${enterpriseEmployeeId}/change-status`, body);

const getLabourDetail = ({
  employeeId,
  enterpriseEmployeeId,
}: {
  employeeId: string;
  enterpriseEmployeeId: number;
}): Promise<EmployeeSetupLabourDetail> =>
  client.get(`/api/v1/employee-setup/${employeeId}/enterprise-employee/${enterpriseEmployeeId}/labour-detail`);

const getPayrollAdvanceSettingEachEnterprise = ({
  employeeId,
  enterpriseEmployeeId,
  pasId,
}: {
  employeeId: string;
  enterpriseEmployeeId: number;
  pasId: number;
}): Promise<EmployeeSetupPayrollAdvanceSetting> =>
  client.get(
    `/api/v1/employee-setup/${employeeId}/enterprise-employee/${enterpriseEmployeeId}/payroll-advance-setting/${pasId}`,
  );

const updatePayrollAdvanceSettingEachEnterprise = ({
  employeeId,
  enterpriseEmployeeId,
  pasId,
  ...body
}: UpdatePayrollAdvanceSettingType) =>
  client.put(
    `/api/v1/employee-setup/${employeeId}/enterprise-employee/${enterpriseEmployeeId}/payroll-advance-setting/${pasId}`,
    body,
  );

const getEmployeeSetupCondition = ({
  employeeId,
  enterpriseEmployeeId,
}: {
  employeeId: string;
  enterpriseEmployeeId: string | number;
}): Promise<EmployeeSetupCondition> =>
  client.get(`/api/v1/employee-setup/${employeeId}/enterprise-employee/${enterpriseEmployeeId}/condition`);

const getEmployeeSupportPrograms = ({
  employeeId,
}: {
  employeeId: string;
}): Promise<SupportProgramPaginateType> =>
  client.get(`/api/v1/employee-setup/${employeeId}/employee-support-program`);

const getEmployeeSupportProgram = ({
  employeeId,
  enterpriseEmployeeId,
  programId,
}: {
  employeeId: number;
  enterpriseEmployeeId: number;
  programId: number;
}): Promise<SupportProgramType> =>
  client.get(`/api/v1/employee-setup/${employeeId}/employee-support-program/enterprise-employee/${enterpriseEmployeeId}/program/${programId}`);

const getEmployeeHisSupportPrograms = (params?: SearchSupportProgramHisType): Promise<SupportProgramHisPaginateType> =>
  client.get(`/api/v1/employee-setup/${params?.employeeId}/employee-support-program/history`, { params });

const createEmployeeSupportProgram = ({ enterpriseEmployeeId, employeeId, ...body }: CreateSupportProgramType) =>
  client.post(`/api/v1/employee-setup/${employeeId}/employee-support-program/enterprise-employee/${enterpriseEmployeeId}/program`, body);

const updateEmployeeSupportProgram = ({ id, enterpriseEmployeeId, employeeId, ...body }: UpdateSupportProgramType) =>
  client.put(`/api/v1/employee-setup/${employeeId}/employee-support-program/enterprise-employee/${enterpriseEmployeeId}/program/${id}`, body);

const listEmployeeEkyc = ({
  id,
}: {
  id: string;
}): Promise<EmployeeEkycListType> =>
  client.get(`/api/v1/ekyc/employee/${id}/list`);


export default {
  updateStatusEmployee,
  updatePayrollAdvanceSettingEachEnterprise,
  getPayrollAdvanceSettingEachEnterprise,
  getLabourDetail,
  getSalaryCondition,
  getEmployeeSetupCondition,
  getPayrollAdvanceSetting,
  fetchEmployeeSetup,
  fetchEmployeeBasicInfo,
  getExpectedPenalRate,
  updateExpectedPenalRate,
  updateOrRemoveBlacklist,
  getEmployeeSupportPrograms,
  getEmployeeSupportProgram,
  getEmployeeHisSupportPrograms,
  createEmployeeSupportProgram,
  updateEmployeeSupportProgram,
  listEmployeeEkyc,
};
