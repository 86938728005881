import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { groupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { GroupCreateType, GroupType } from 'types/Group';
import { DepartmentType } from 'types/User';
import { GroupUpdatePopup } from 'views/Department/popups';
import { TableEmpty } from 'components';

type PopupProps = PopupController & {
  department: DepartmentType;
};

const GroupCreatePopup = ({ department, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const [dataSearch, onSearchChange] = useSearch({ department });
  const [isOpenUpdate, setOpenUpdate] = useState(false);
  const [chosenGroup, setChosenGroup] = useState<GroupType>();

  const { data } = useQuery(['groupService.fetchGroups', dataSearch], () => groupService.fetchGroups(dataSearch), {
    keepPreviousData: true,
  });
  const { data: items = [], paginate } = data ?? {};

  const { mutate: createGroup, isLoading } = useMutation(groupService.createGroup, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('groupService.fetchGroups');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: GroupCreateType) => {
      createGroup({
        ...values,
        department,
      });
    })();
  };

  return isOpenUpdate ? (
    <GroupUpdatePopup group={chosenGroup!} onClose={() => setOpenUpdate(false)} />
  ) : (
    <>
      <DialogTitle>
        <Trans>Add Group</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-start'>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            rules={{ required: t`Group Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Group Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <LoadingButton variant='contained' className='min-w-fit ml-6' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Add Group</Trans>
          </LoadingButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Group</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Leader</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Member</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.leaderName}</TableCell>
                  <TableCell className='text-center'>{item.totalMembers ?? 0}</TableCell>
                  <TableCell className='text-right'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenUpdate(true);
                        setChosenGroup(item);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default GroupCreatePopup;
