import { t, Trans } from '@lingui/macro';

import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEnterpriseStatus } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { transactionService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { EnterpriseMeetingCalendar, EnterpriseMeetingSummary } from 'views/Enterprise/EnterpriseList/components';
import { formatNumber } from 'utils/common';
import { IS_EPAYZ } from 'env';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { ViewFile } from 'views/Transaction/TransPaying/TransNotify'
import './index.css'
import { downloadBase64 } from 'utils/common';
// import {
//   EnterpriseAssignmentPopup,
//   EnterpriseCreatePopup,
//   EnterpriseCreateSelectPopup,
// } from 'views/Enterprise/EnterpriseList/popups';
const TransactionNoticeList = () => {
  const { requireUserPCManager, requireUserPCManagerOrCRMLeader, requreUserPermission, ENTERPRISE_CREATE, ENTERPRISE_VIEW, ENTERPRISE_ASSIGN } = usePermission();
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({ type : 'employeeName'});
  const [isOpenView, setOpenView] = useState(false);
  const [objectKey, changeobjectKey] = useState('');
  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<EnterpriseType[]>([]);
  const [isLoadingExport, setLoadingExport] = useState(false);
  const { data } = useQuery(
    ['transactionService.listTransactionNotice', dataSearch],
    () => transactionService.listTransactionNotice(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate, summary } = data ?? {};
  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );
  const exportReport = () => {
    setLoadingExport(true);
    transactionService
      .exportExcelData(dataSearch)
      .then(downloadBase64)
      .finally(() => {
        setLoadingExport(false);
      });
  };



  return (
    <div>
      <div className='flex justify-between'>
        <div className='space-x-3 divParent'>

        <div className='divChild1'>
          <Trans>Payment Amount to { IS_EPAYZ ? "IFZ" : "SCP" }</Trans>
        </div>
        <div className='divChild2'>
          {formatNumber(summary?.totalCollectionAmount ? summary.totalCollectionAmount : 0)}
        </div>
        <div>
          <LoadingButton variant='contained' loading={isLoadingExport} onClick={exportReport}>
            <Trans>Export</Trans>
          </LoadingButton>
        </div>
        </div>
        <div className='space-x-3' style={{ display:'flex', alignItems:'center' }}>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              setSearchText('');
              debounceChangeValue({ type: event.target.value, search: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='cardIdNo'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='staffId'>
              <Trans>Staff ID</Trans>
            </MenuItem>
            <MenuItem value='transactionId'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
          </TextField>
          <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ search: event.target.value });
              }}
            />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID Card No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Staff ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transferred Income (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (

              <TableRow key={item.id}>
                  <TableCell align='center' className='p-1'>
                       {(idx + 1) + ((paginate?.page ? paginate?.page : 1) - 1) * (paginate?.size ? paginate?.size : 10)}
                  </TableCell>
                <TableCell>{item.transactions.employee?.name}</TableCell>
                <TableCell>{item.transactions.employee?.ercNumber}</TableCell>
                <TableCell>{item.transactions.employeeCode}</TableCell>
                <TableCell>{item.transactions.code}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{formatNumber(item.transactions.requestAmount)}</TableCell>
                <TableCell className='text-right'>
                    <Button size='small' color='info' onClick={()=>{ changeobjectKey(item.object.key); setOpenView(true); }}>
                      <Trans>View</Trans>
                    </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={isOpenView} onClose={() => setOpenView(false)}>
        {<ViewFile
          keykhac={objectKey}
          title='TRANSACTION NOTICE'
          onClose={() => setOpenView(false)}
        />}
      </Dialog>
    </div >
  );
};

export default TransactionNoticeList;
