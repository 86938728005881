import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { useSearch } from 'hooks';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { queryClient, transactionService } from 'services';
import { TransType } from 'types/Transaction';
import { PayingStatus, PaidStatus } from 'constants/transaction';
import { TableEmpty } from 'components';
import { TransAcb, TransBankPopup, TransVcb, TransVietin } from 'views/Transaction/TransPaying/TransReport';
import { useActiveLocale } from 'hooks/useActiveLocale';
const TransPaying = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ statuses: PayingStatus });
  const [dataSelect, setDataSelect] = useState<TransType[]>([]);
  const [searchType, setSearchType] = useState('employeeName');
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const userLocale = useActiveLocale();
  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: moveStatuses, isLoading } = useMutation(transactionService.moveStatuses, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactions');
    },
  });

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const handleClickSubmit = () => {
    moveStatuses({
      ids: dataSelect.map((item) => item.id),
      status: PaidStatus,
    });
  };

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction List</Trans>
          </Typography>
          <Button variant='contained' onClick={() => setOpenSelectBank(true)}>
            <Trans>Export Data</Trans>
          </Button>
          <LoadingButton
            variant='contained'
            disabled={dataSelect.length === 0}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            <Trans>Make payment</Trans>
          </LoadingButton>
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='employeeErcNumber'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value='enterpriseName'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value='bankName'>
              <Trans>Bank Name</Trans>
            </MenuItem>
          </TextField>
          <TextField
            placeholder={t`Search...`}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID Card No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Transaction Cost (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Bank</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account number</Trans>
              </TableCell>
              <TableCell>
                <Trans>{userLocale === 'vi-VN' ? "Giao dịch chuyển tiền" : "Transaction Transfer"}</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center' className='p-1'>
                  <Checkbox
                    checked={dataSelect.some((next) => next.id === item.id)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                    }}
                  />
                </TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell align='center'>
                  {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.employee?.ercNumber}</TableCell>
                <TableCell>{item.enterprise?.name}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.costAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                <TableCell>{item.bank?.name}</TableCell>
                <TableCell>{item.bankAccountNumber}</TableCell>
                <TableCell>{item.transactionTransferStatus === "00" ? (userLocale === 'vi-VN' ? "Thành công" : "Success") : (userLocale === 'vi-VN' ? "Thất bại" : "Failed")}{item.transactionTransferStatus === "00" ? "" : ("(" + item.transactionTransferReason + ")")}</TableCell>
                <TableCell align='center'>
                  <Link to={privateRoute.transactionPayingView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>
      <Dialog fullWidth maxWidth='md' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <TransBankPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='xl' open={openExport} onClose={() => setOpenExport(false)}>
        {bankCode === 'ACB' && (
          <TransAcb
            bankCode={bankCode}
            onClose={() => {
              setOpenExport(false);
            }}
          />
        )}
        {bankCode === 'VIETINBANK' && (
          <TransVietin
            bankCode={bankCode}
            onClose={() => {
              setOpenExport(false);
            }}
          />
        )}
        {bankCode === 'VIETCOMBANK' && (
          <TransVcb
            bankCode={bankCode}
            onClose={() => {
              setOpenExport(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default TransPaying;
