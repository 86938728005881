import { t, Trans } from '@lingui/macro';

import {
  Button, debounce, MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { transactionOverdueService } from 'services';
import { formatNumber } from 'utils/common';

const TransCompleted = () => {
  const [dataSearch, onSearchChange] = useSearch({});
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionOverdueService.fetchCompletedTransactions', dataSearch],
    () => transactionOverdueService.fetchCompletedTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>List of Transaction</Trans>
          </Typography>
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value='guaranteeStatus'>
              <Trans>Guarantee Status</Trans>
            </MenuItem>
          </TextField>
          {['employeeName', 'transactionCode'].includes(searchType) && (
            <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            />
          )}
          {['guaranteeStatus'].includes(searchType) && (
            <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              <MenuItem value='GUARANTEE'>
                <Trans>Guarantee</Trans>
              </MenuItem>
              <MenuItem value='NON_GUARANTEE'>
                <Trans>Non-Guarantee</Trans>
              </MenuItem>
            </TextField>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Overdue Starting Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>No. of Overdue Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Guarantee Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Overdue Transfering Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Completion Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Assigned CRM</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.transactionCode}</TableCell>
                <TableCell align='center'>
                  {item.overdueStartingDate ? DateTime.fromISO(item.overdueStartingDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-red-600'>
                  +{formatNumber(item.numbOfOverdueDate)} {t`days`}
                </TableCell>
                <TableCell>{item.employeeName}</TableCell>
                <TableCell>{item.guaranteeStatus === 'GUARANTEE' ? t`Guarantee` : t`Non-Guarantee`}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.overdueTransferringAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedAmount)}</TableCell>
                <TableCell align='center'>
                  {item.overdueStartingDate ? DateTime.fromISO(item.completionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.assignedCrm}</TableCell>
                <TableCell align='center'>
                  <Link to={privateRoute.transactionOverdueView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default TransCompleted;
