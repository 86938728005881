import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { usePermission, useSearch } from 'hooks';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient, userService } from 'services';
import { DigitalAssignmentPopup } from 'views/Department/popups';

const DigitalSigning = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole, requreUserPermission, KYDIENTU_THEM, KYDIENTU_SUA } = usePermission();

  const [dataSearch, onSearchChange] = useSearch({
    assignedDs: true,
    status: 'active',
    role: 'SUB_MASTER,CRM_SENIOR,CA_SENIOR,ACC_CONTROLLER',
  });
  const [loading, setLoading] = useState(false);
  const [isOpenAssign, setOpenAssign] = useState(false);

  const [userToChangeAssignmentStatus, setUserToChangeAssignmentStatus] = useState<null | number>(null);

  const handleChangeAssignmentStatusUser = () => {
    if (userToChangeAssignmentStatus) {
      const user = users.find((item) => item.id === userToChangeAssignmentStatus);
      if (!user) return;

      setLoading(true);
      userService
        .updateAssignmentStatus({ id: userToChangeAssignmentStatus as number, allowDs: !user.allowDs })
        .then(() => {
          enqueueSnackbar(t`Successful`);
          setUserToChangeAssignmentStatus(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const { data } = useQuery(['userService.fetchUsers', dataSearch], () => userService.fetchUsers(dataSearch), {
    keepPreviousData: true,
  });

  const { data: users = [], paginate } = data ?? {};

  useEffect(() => {
    if (userToChangeAssignmentStatus === null) queryClient.invalidateQueries('userService.fetchUsers');
  }, [userToChangeAssignmentStatus]);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Digital Signing</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex items-center space-x-3 justify-start'>
          <Typography variant='h4'>
            <Trans>List of Assigned Accounts</Trans>
          </Typography>
          {requireRole('MASTER') && requreUserPermission([KYDIENTU_THEM]) && (
            <Button variant='contained' onClick={() => setOpenAssign(true)}>
              <Trans>Add</Trans>
            </Button>
          )}
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Role</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Position</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Email</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Phone</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.role.name}</TableCell>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.position}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell align='center'>
                    {(() => {
                      return (
                        <Switch
                          disabled={!requreUserPermission([KYDIENTU_SUA])}
                          checked={user.allowDs}
                          onClick={() => {
                            setUserToChangeAssignmentStatus(user.id);
                          }}
                        />
                      );
                    })()}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={users} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center'>
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>

        <Dialog
          fullWidth
          maxWidth='xs'
          open={!!userToChangeAssignmentStatus}
          onClose={() => {
            setUserToChangeAssignmentStatus(null);
          }}
        >
          <DialogTitle>
            <Trans>Are you sure?</Trans>
          </DialogTitle>
          <DialogContent>
            <Trans>
              <Trans>
                Do you want to{' '}
                {users.find((user) => user.id === userToChangeAssignmentStatus)?.allowDs ? t`deactive` : t`active`} user{' '}
                {users.find((user) => user.id === userToChangeAssignmentStatus)?.fullname}?
              </Trans>
            </Trans>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant='outlined'
              onClick={() => {
                setUserToChangeAssignmentStatus(null);
              }}
            >
              <Trans>Cancel</Trans>
            </LoadingButton>
            <LoadingButton loading={loading} variant='contained' onClick={handleChangeAssignmentStatusUser}>
              <Trans>Update</Trans>
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth='sm' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
          <DigitalAssignmentPopup onClose={() => setOpenAssign(false)} />
        </Dialog>
      </div>
    </Paper>
  );
};

export default DigitalSigning;
