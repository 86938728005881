import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography, Button, Chip } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { templateService, employeeLaborService, queryClient } from 'services';
import { downloadBase64 } from 'utils/common';
import { useMutation } from 'react-query';

type PopupProps = {
  value: string;
  onBack: () => void;
  onNext: () => void;
  setValue: (args: any) => void;
};

const AttendanceUpdatePlatform = ({ value, onBack, onNext, setValue }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();

  const { mutate: uploadFileSystem, isLoading } = useMutation(employeeLaborService.uploadFileSystem, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeLaborService.fetchEmployeeLabors');
      onNext();
    },
  });

  const handleClickSubmit = () => {
    if (fileSelected && value) {
      const formData = new FormData();
      formData.append('file', fileSelected, fileSelected.name);
      uploadFileSystem(formData);
    } else {
      enqueueSnackbar('Please select file', { variant: 'warning' });
    }
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };
  return (
    <>
      <DialogTitle>
        <Trans>UPDATE LABOUR BY PLATFORM'S TEMPLATE</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-end space-x-3'>
          <Typography variant='h6'>
            <Trans>Download the template</Trans>
          </Typography>
          <Button
            size='small'
            color='info'
            onClick={() => {
              templateService.downloadFileBase64('import-labor-employee').then((res) => {
                downloadBase64(res);
              });
            }}
          >
            <Trans>WorkDay Information</Trans>
          </Button>
        </div>
        <div className='mt-6'>
          <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => {
                  setFileSelected(null);
                  if (inputRef.current?.value) {
                    inputRef.current.value = '';
                  }
                }}
              />
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button
            variant='outlined'
            onClick={() => {
              onBack();
              setValue('');
            }}
          >
            <Trans>Back</Trans>
          </Button>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            size='small'
            startIcon={<Upload />}
            onClick={handleClickSubmit}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default AttendanceUpdatePlatform;
