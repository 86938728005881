import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch, usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { userService, queryClient } from 'services';
import { DepartmentType, UserType } from 'types/User';
import { GroupCreatePopup, UserCreatePopup, UserRemovePopup } from 'views/Department/popups';
import { LoadingButton } from '@mui/lab';
import { signOut } from 'reducers/profile';
import { useDispatch } from 'react-redux';
const UserList = ({ department }: { department: DepartmentType }) => {
  const roles: any = {
    MANAGER: 'SUB_MASTER',
    CRM: 'CRM_SENIOR',
    CA: 'CA_SENIOR',
    ACCOUNTING: 'ACC_CONTROLLER',
  };
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole, requreUserPermission, PHANQUYEN_XOA, PHANQUYEN_THEMNHOM, PHANQUYEN_THEMNGUOIDUNG, requireUserPCManager } = usePermission();
  const [dataSearch, onSearchChange] = useSearch({ department });

  const [isOpenChangeStatus, setChangeStatus] = useState(false);
  const [userToChangeStatus, setUserToChangeStatus] = useState<UserType>();

  const [isOpenGroup, setOpenGroup] = useState(false);
  const [isOpenCreate, setOpenCreate] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [chosenUser, setChosenUser] = useState<UserType>();

  const { data } = useQuery(['userService.fetchUsers', dataSearch], () => userService.fetchUsers(dataSearch), {
    keepPreviousData: true,
  });
  const { data: items = [], paginate } = data ?? {};

  const { mutate: updateStatus, isLoading } = useMutation(userService.updateStatus, {
    onSuccess: (result: any) => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('userService.fetchUsers');
      setChangeStatus(false);
      // if (result.isLoggedOut) {
      //   dispatch(signOut());
      // }
    },
  });

  const handlerChangeStatusClick = () => {
    updateStatus({
      id: userToChangeStatus?.id!,
      status: userToChangeStatus?.status === 'active' ? 'inactive' : 'active',
    });
  };

  const allowActive = (role: string, department: string) => {
    if (role === "SUB_MASTER") {
      return requireRole("MASTER", false);
    }
    if (role === "CRM_SENIOR" || role === "CA_SENIOR" || role === "ACC_CONTROLLER") {
      return requireRole("SUB_MASTER");
    }
    return requireRole(roles[department]);
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <Typography variant='h4'>
          <Trans>List User</Trans>
        </Typography>
        <div className='flex space-x-3'>

          {/* {requireRole(roles[department]) && ['CA', 'CRM'].includes(department) && requreUserPermission([PHANQUYEN_THEMNHOM]) && ( */}
          {requireUserPCManager() && requreUserPermission([PHANQUYEN_THEMNHOM]) && (
            <Button variant='contained' onClick={() => setOpenGroup(true)}>
              <Trans>Add Group</Trans>
            </Button>
          )}
          {requireRole(roles[department]) && requreUserPermission([PHANQUYEN_THEMNGUOIDUNG]) && (
            <Button variant='contained' onClick={() => setOpenCreate(true)}>
              <Trans>Add User</Trans>
            </Button>
          )}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {['CA', 'CRM'].includes(department) && (
                <TableCell>
                  <Trans>Group</Trans>
                </TableCell>
              )}
              <TableCell>
                <Trans>Role</Trans>
              </TableCell>
              <TableCell>
                <Trans>Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell>
                <Trans>Email</Trans>
              </TableCell>
              <TableCell>
                <Trans>Phone</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                {['CA', 'CRM'].includes(department) && <TableCell>{item.group?.name}</TableCell>}
                <TableCell>{item.role?.name}</TableCell>
                <TableCell>{item.fullname}</TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                {item.status === 'invited' ? (
                  <TableCell>
                    <Button variant='outlined' size='small'>
                      <Trans>invited</Trans>
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell>
                    {(() => {
                      return allowActive(item.role?.name, department) ? (
                        <Switch
                          checked={item.status === 'active'}
                          onClick={() => {
                            setChangeStatus(true);
                            setUserToChangeStatus(item);
                          }}
                        />
                      ) : (
                        <Switch checked={item.status === 'active'} />
                      );
                    })()}
                  </TableCell>
                )}
                <TableCell className='text-right'>
                  {requireRole(roles[department]) && requreUserPermission([PHANQUYEN_XOA]) && (
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setOpenRemove(true);
                        setChosenUser(item);
                      }}
                    >
                      <Trans>Remove</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='sm' open={isOpenGroup} onClose={() => setOpenGroup(false)}>
        <GroupCreatePopup department={department} onClose={() => setOpenGroup(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='xs' open={isOpenCreate} onClose={() => setOpenCreate(false)}>
        <UserCreatePopup department={department} onClose={() => setOpenCreate(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='xs' open={isOpenRemove} onClose={() => setOpenRemove(false)}>
        <UserRemovePopup user={chosenUser!} onClose={() => setOpenRemove(false)} />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={isOpenChangeStatus}
        onClose={() => {
          setChangeStatus(false);
        }}
      >
        <DialogTitle>
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>
            <Trans>
              Do you want to {userToChangeStatus?.status === 'active' ? t`deactive` : t`active`} user{' '}
              {userToChangeStatus?.fullname}?
            </Trans>
          </Trans>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={() => {
              setChangeStatus(false);
            }}
          >
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton loading={isLoading} variant='contained' onClick={handlerChangeStatusClick}>
            <Trans>Update</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserList;
