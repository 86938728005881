import { t, Trans } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { employeeSetupService, queryClient } from 'services';
import { formatNumber } from 'utils/common';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const FullTimePopupStep2 = ({ pasId, enterpriseEmployeeId, employeeId, onBack, onNext }: PopupProps) => {
  const {
    data: {
      typeOfLaborContractEn,
      contractAdvanceRatio,
      seniority,
      seniorityAdvanceRatio,
      approvalRatio,
      levelEn,
      maximumLimit,
      minimumLimit,
      advanceLimit,
      earnedDiscountRate,
      futureDiscountRate,
      futureAdvance,
    } = {},
  } = useQuery(
    ['employeeSetupService.getPayrollAdvanceSettingEachEnterprise', { employeeId, enterpriseEmployeeId, pasId }],
    () => employeeSetupService.getPayrollAdvanceSettingEachEnterprise({ employeeId, enterpriseEmployeeId, pasId }),
    {
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value);
        });
      },
    },
  );

  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });

  const { monthlyAverageSalary } = watch();

  const { mutate: updatePayrollAdvanceSettingEachEnterprise, isLoading } = useMutation(
    employeeSetupService.updatePayrollAdvanceSettingEachEnterprise,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employeeSetupService.getSalaryCondition');
        onNext();
      },
    },
  );

  const handleClickSubmit = () => {
    //console.log(monthlyAverageSalary)
    //return;
    handleSubmit(() => {
      updatePayrollAdvanceSettingEachEnterprise({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        pasId,
        monthlyAverageSalary,
        status: 'PAS_STATE_CONFIRM',
      });
    })();
  };

  const limitByLaborContractSeniority = (monthlyAverageSalary * approvalRatio!) / 100;

  const handleAdvanceLimit = () => {
    if (minimumLimit! <= limitByLaborContractSeniority && limitByLaborContractSeniority <= maximumLimit!) {
      return formatNumber(limitByLaborContractSeniority);
    } else if (minimumLimit! > limitByLaborContractSeniority) {
      return 0;
    } else if (maximumLimit! < limitByLaborContractSeniority) {
      return formatNumber(maximumLimit);
    } else {
      return formatNumber(advanceLimit);
    }
  };
  const {
    data: {
      currentTermNumbOfEarnedLabour,
      currentTermNumbOfDefaultLabour,
    } = {},
  } = useQuery(['employeeSetupService.getLabourDetail', { employeeId, enterpriseEmployeeId }], () =>
    employeeSetupService.getLabourDetail({ employeeId, enterpriseEmployeeId }),
  );
  return (
    <>
      <DialogTitle>
        <Trans>CREDIT SETUP FOR FULL-TIME EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h4'>
          <Trans>Payroll Advance Setting</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={8} xl={8}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Monthly Average Salary (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='monthlyAverageSalary'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`Monthly Average Salary is required`, min: 0 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            label={t`Monthly Average Salary`}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{ inputComponent: InputNumber as any }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Type of Labor Contract</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={typeOfLaborContractEn ?? ''} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={contractAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Seniority (Year)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniority ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={seniorityAdvanceRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit by Labor Contract & Seniority (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(limitByLaborContractSeniority)} />
                    </TableCell>
                    <TableCell>
                      <Trans>Approval Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={approvalRatio ?? 0} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Level</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={levelEn ?? ''} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Minimum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(minimumLimit) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Maximum Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(maximumLimit) ?? 0} />
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>
                      <Trans>Applied Enterprise's Fee</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={appliedEnterpriseFeeEn ?? ''} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee Support Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={employeeSupportDiscountRate ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell>
                      <Trans>Employee has been eligible for future advance</Trans>
                    </TableCell>
                    <TableCell>
                      {/* <TextField InputProps={{ readOnly: true }} value={appliedEnterpriseFeeEn ?? ''} /> */}
                      {futureAdvance ? t`YES` : t`NO`}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Expected Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfDefaultLabour}
                    </TableCell>
                    <TableCell>
                      <Trans>Earned Labours in term</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermNumbOfEarnedLabour}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit as earned labours (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(Math.round((monthlyAverageSalary! * currentTermNumbOfEarnedLabour!) / currentTermNumbOfDefaultLabour!)) ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} lg={4} xl={4}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>APPROVAL DECISION</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>{handleAdvanceLimit()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{earnedDiscountRate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{futureDiscountRate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Confirm</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default FullTimePopupStep2;