import { Trans, t } from '@lingui/macro';
import {
  Button,
  debounce,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { transactionService } from 'services';
import { getTransactionStatus, SettledStatus, WaitSettlementApprovalStatus } from 'constants/transaction';
import { TableEmpty } from 'components';

const TransSettled = () => {
  const [dataSearch, onSearchChange] = useSearch({ statuses: `${WaitSettlementApprovalStatus},${SettledStatus}` });
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction List</Trans>
          </Typography>
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='employeeErcNumber'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value='enterpriseName'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
          </TextField>
          <TextField
            placeholder={t`Search...`}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Maturity Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collection Amount from Employee (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.code}</TableCell>
                <TableCell align='center'>
                  {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.enterprise?.name}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                <TableCell align='center'>
                  {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectionAmount)}</TableCell>
                <TableCell>{getTransactionStatus(item.status)}</TableCell>
                <TableCell align='center'>
                  <Link to={privateRoute.transactionWaitSettlementView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center'>
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ page: value })}
        />
      </div>
    </div>
  );
};

export default TransSettled;
