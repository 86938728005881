import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { useActiveLocale } from 'hooks/useActiveLocale';
import { getEmployeeType } from 'constants/employee';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  enterpriseEmployeeId: number;
  employeeId: string;
};

const FullTimePopup = ({ employeeId, enterpriseEmployeeId, onSuccess, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  const {
    data: {
      employeeType,
      divisionVi,
      divisionEn,
      attendanceClosingDate,
      attendanceStartingDate,
      currentTermAttendanceStartingDate,
      currentTermAttendanceClosingDate,
      salaryPaymentDate,
      currentTermSalaryPaymentDate,
      currentTermNumbOfEarnedLabour,
      currentTermNumbOfDefaultLabour,
      currentTermMaxLaboursOfAdvance,
      attendanceTimeVi,
      attendanceTimeEn,
      fullDayOffVi,
      fullDayOffEn,
      halfFullDayOffVi,
      halfFullDayOffEn,
    } = {},
  } = useQuery(['employeeSetupService.getLabourDetail', { employeeId, enterpriseEmployeeId }], () =>
    employeeSetupService.getLabourDetail({ employeeId, enterpriseEmployeeId }),
  );

  return (
    <>
      <DialogTitle>
        <Trans>Labour Detail</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3} className='flex items-center'>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee Type</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={getEmployeeType(employeeType) ?? ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Division</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={(userLocale === 'vi-VN' ? divisionVi : divisionEn) ?? ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Attendance Time</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={(userLocale === 'vi-VN' ? attendanceTimeVi : attendanceTimeEn) ?? ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>(1) Full day off</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={(userLocale === 'vi-VN' ? fullDayOffVi : fullDayOffEn) ?? ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>(2) Half/Full day off</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={userLocale === 'vi-VN' ? halfFullDayOffVi : halfFullDayOffEn ?? ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Attendance Starting Date</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='flex items-center justify-between'>
                        <TextField InputProps={{ readOnly: true }} value={attendanceStartingDate ?? ''} />
                        <Typography variant='subtitle2'>
                          <Trans>Every month</Trans>
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Attendance Closing Date</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='flex items-center justify-between'>
                        <TextField InputProps={{ readOnly: true }} value={attendanceClosingDate ?? ''} />
                        <Typography variant='subtitle2'>
                          <Trans>Every month</Trans>
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Salary Payment Date</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='flex items-center justify-between'>
                        <TextField InputProps={{ readOnly: true }} value={salaryPaymentDate ?? ''} />
                        <Typography variant='subtitle2'>
                          <Trans>Every month</Trans>
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>CURRENT TERM</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Attendance Starting Date</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermAttendanceStartingDate
                        ? DateTime.fromISO(currentTermAttendanceStartingDate).toFormat('dd/MM/yyyy')
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Attendance Closing Date</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermAttendanceClosingDate
                        ? DateTime.fromISO(currentTermAttendanceClosingDate).toFormat('dd/MM/yyyy')
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Salary Payment Date</Trans>
                    </TableCell>
                    <TableCell>
                      {currentTermSalaryPaymentDate
                        ? DateTime.fromISO(currentTermSalaryPaymentDate).toFormat('dd/MM/yyyy')
                        : ''}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>No. of Default Labour</Trans>
                    </TableCell>
                    <TableCell>{currentTermNumbOfDefaultLabour}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>No. of Earned Labour</Trans>
                    </TableCell>
                    <TableCell>{currentTermNumbOfEarnedLabour}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Max Labours of Advance</Trans>
                    </TableCell>
                    <TableCell>{currentTermMaxLaboursOfAdvance}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FullTimePopup;
